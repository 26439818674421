import Home from "pages";
import SetUp from "pages/setup";
import CreatePin from "pages/create-pin";
import Dashboard from "features/dashboard/dashboard-nav";
import DashboardHome from "pages/dashboard";
import Requests from "pages/requests";
import Transactions from "pages/transactions";
import Settings from "pages/settings";
import SetBankDetailsModal from "pages/set-bank-details";
import ChangePin from "features/settings/change-pin/change-pin";
import ContactUs from "features/settings/contact-us/contact-us";
import ResetPassword from "features/settings/reset-password/reset-password";
import PayMerchants from "pages/pay-merchant";
import PayBill from "pages/pay-bill";
import Bills from "pages/bills";
import PeerTransfer from "pages/peer-transfer";
import { AuthContextProvider } from "features/auth/auth-context";
import { BillContextProvider } from "features/bills/bills.context";
import Accounts from "pages/accounts";
import AccountsPaid from "pages/accounts-paid";
import Withdraw from "pages/withdraw";
import SetupProfile from "pages/setup-profile";
import FundAccountCreate from "pages/fund-account-create";
import FundAccountActions from "pages/fund-account-actions";

export const protectedRoutes = [
   {
      element: <AuthContextProvider />,
      children: [
         { path: "/", element: <Home /> },
         { path: "create-pin", element: <CreatePin /> },
         { path: "setup", element: <SetUp /> },
         { path: "setup/profile", element: <SetupProfile /> },
         { path: "accounts/funding/create", element: <FundAccountCreate /> },
         { path: "accounts/funding/actions", element: <FundAccountActions /> },
         {
            path: "dashboard",
            element: <Dashboard />,
            children: [
               {
                  index: true,
                  element: <DashboardHome />,
               },
               { path: "transactions", element: <Transactions /> },
               { path: "requests", element: <Requests /> },

               { path: "accounts/withdraw", element: <Withdraw /> },

               { path: "pay-merchant/:step", element: <PayMerchants /> },
               { path: "peer-transfer/:step", element: <PeerTransfer /> },
               {
                  element: <BillContextProvider />,
                  children: [
                     { path: "bills", element: <Bills /> },
                     { path: "pay-bill", element: <PayBill /> },
                     { path: "accounts/active", element: <Accounts /> },
                     { path: "accounts/paid", element: <AccountsPaid /> },
                  ],
               },

               {
                  path: "settings",
                  children: [
                     { index: true, element: <Settings /> },
                     { path: "change-pin", element: <ChangePin /> },
                     { path: "bank-details", element: <SetBankDetailsModal /> },
                     { path: "reset-password", element: <ResetPassword /> },
                     { path: "contact-us", element: <ContactUs /> },
                  ],
               },
            ],
         },
      ],
   },
];
