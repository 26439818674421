import * as yup from "yup";
import NumberFormat from "react-number-format";

import Input from "features/ui/forms/input";
import Button from "features/ui/forms/button";
import Form from "features/ui/forms/form";
import ArrowBack from "features/ui/layout/nav/arrow-back";
import ModalContainer from "features/modal/modal-container.component";
import AccountCard from "features/shared/forms/bank-card";

export default function AmountModal({
   onClose,
   isOpen,
   balance,
   handleAmountSubmit,
   bankDetails,
}) {
   const formOptions = {
      mode: "onTouched",
      criteriaMode: "all",
      defaultValues: { amount: "" },
   };

   const schema = yup.object().shape({
      amount: yup
         .number("please enter a valid amount")
         .positive("please enter a valid amount")
         .required("amount cannot be empty")
         .typeError("please enter a valid amount"),
   });

   return (
      <ModalContainer size="THIN" isOpen={isOpen} onClose={onClose}>
         <ArrowBack to={"../"} color="GREY" />
         <div className="full-page container justify-center px-10 md:py-12">
            <h2 className="poppins-bold mb-16 text-left text-2xl font-semibold capitalize text-black-one">
               enter amount
            </h2>

            <p className="poppins-semibold  self-center text-center text-lg text-black-two">
               {balance}
            </p>
            <p className="poppins-regular self-center text-center text-xs text-black-four">
               bal
            </p>

            <Form
               id="amount-form"
               options={formOptions}
               onSubmit={(data, reset) => handleAmountSubmit(data, reset)}
               schema={schema}
               className="self-stretch text-black-one"
            >
               {({
                  control,
                  Controller,
                  formState: { errors, dirtyFields },
               }) => {
                  return (
                     <Controller
                        control={control}
                        name="amount"
                        render={({ field: { onChange, name, value } }) => (
                           <NumberFormat
                              variant="BASE"
                              prefix={"₦ "}
                              thousandSeparator={true}
                              label="amount"
                              placeholder="₦"
                              isEmpty={!dirtyFields.amount}
                              error={errors.amount}
                              customInput={Input}
                              name={name}
                              value={value}
                              onValueChange={(v) => {
                                 onChange(Number(v.value));
                              }}
                           />
                        )}
                     />
                  );
               }}
            </Form>
            <div className="h-[40px] w-[2px] bg-black-four"></div>
            <AccountCard
               bankName={bankDetails.bankName}
               accountNo={bankDetails.accountNumber}
               name={bankDetails.accountName}
            />
            <Button type="submit" form="amount-form">
               proceed
            </Button>
            <p className="poppins-regular mt-16 pl-1 text-xs text-black-two">
               note that a processing fee of ₦20 is charged by the bank
            </p>
         </div>
      </ModalContainer>
   );
}
