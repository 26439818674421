import { useEffect, useState } from "react";
import QRCode from "../../../src/assets/icons/LedgaV2 Icons/QR code Black.svg";

export default function SettingsFormColored({ userData }) {
   const [memberName, setMemberName] = useState("");
   const [profileImage, setProfileImage] = useState("");
   const [userId, setUserId] = useState("");

   useEffect(() => {
      if (userData) {
         const {
            name,

            profile_image,
            unique_id,
         } = userData;
         setMemberName(name);
         setProfileImage(profile_image);
         setUserId(unique_id);
      }
   }, [userData, memberName]);

   // const handleSubmit = (e) => {
   //    e.preventDefault();
   // };

   return (
      <div className="shadow-faintShadow poppins mx-auto mb-[20px] w-5/6 max-w-screen-sm rounded-[10px] bg-white py-[30px] px-[20px] shadow">
         <div className="flex flex-row items-center justify-between gap-2">
            <div className="flex gap-[10px]">
               <img
                  src={profileImage}
                  alt="user profile"
                  className="border- h-[40px] w-[40px] rounded-[50%] border border-white shadow-md shadow-faintShadow-one"
               />
               <div>
                  <p className="poppins-bold font-bold">{memberName}</p>
                  <p className="poppins-regular text-black-three">{userId}</p>
               </div>
            </div>

            <img alt="qr code" src={QRCode} />
         </div>
      </div>
   );
}
