import { Outlet } from "react-router-dom";
import { createContext, useContext } from "react";

import { useFormData } from "utils/hooks/useFormData";

const billContext = createContext();
const useBillContext = () => useContext(billContext);

function BillContextProvider() {
   const initialFormData = {
      id: "",
      amount: "",
      billName: "",
      formattedAmount: "",
      minimumAmount: "",
      formattedMinimumAmount: "",
      paymentPurpose: "",
      makePartPayment: "",
      partPaymentIsAllowed: "",
   };
   const [state, dispatch] = useFormData(initialFormData);

   return (
      <billContext.Provider value={{ state, dispatch }}>
         <Outlet />
      </billContext.Provider>
   );
}

export { useBillContext, BillContextProvider };
