import * as REGEX from "utils/constants/regex";
import NumberFormat from "react-number-format";
import { IoArrowBack } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
// import Input from "features/shared/forms/input";
import Select from "features/shared/forms/select";
import Button from "features/shared/forms/button";
import FormGroup from "features/shared/forms/form-group";
import PageTitle from "features/shared/layout/headers/page-title";
import { useEffect } from "react";
import Input from "features/ui/forms/input";
import UserCard from "features/ui/cards/user-card";
import UserImg from "../../assets/icons/LedgaV2 Icons/Add User.svg";
import Header from "features/shared/layout/headers/dashboard-header";

export default function TransactionDetails({
   optionsObj,
   selectLabel,
   handleNext,
   state,
   dispatch,
   pageName,
}) {
   const navigate = useNavigate();

   //redirect to page 1 if user tries to access page 2 directly without
   //filling the form, ensure no redirect if the from is cleared onSubmit
   useEffect(() => {
      if (
         !state.id ||
         !state.recipientName ||
         state.recipientName.includes("account found")
      ) {
         navigate("../peer-transfer/1", { replace: true });
      }
      // eslint-disable-next-line
   }, []);

   const SelectOptions = optionsObj || [
      { name: "food", icon: "food.svg" },
      { name: "payment", icon: "atm_card.svg" },
      { name: "books", icon: "book_mark.svg" },
      { name: "transportation", icon: "transportation.svg" },
      { name: "peer transfer", icon: "gift.svg" },
   ];
   //styles

   const handleNumberFormat = (values, input, formattedInput) => {
      const { value, formattedValue } = values;
      dispatch({
         type: "UPDATE_INPUT",
         payload: { [input]: value, [formattedInput]: formattedValue },
      });
   };

   return (
      <section className="flex min-h-screen flex-col items-center justify-center bg-[#F3F3F3] px-10 pb-10 md:ml-32 md:justify-start md:pt-10 lg:ml-48">
         <div className="container-alt2 md:w-[600px] md:items-center md:justify-center ">
            <IoArrowBack
               onClick={() => navigate(`../../../dashboard/${pageName}/1`)}
               className="fixed top-5 left-4 w-12 cursor-pointer text-4xl text-accent-one md:hidden md:w-8"
            />
            <PageTitle variant="PRIMARY" title="Enter Amount" message="" />
            <div className="mb-10 flex flex-col self-stretch">
               <div className="md:self-center">
                  <UserCard
                     name={state.recipientName}
                     code={state.recipientCode}
                     image={state.image}
                  />
               </div>

               <FormGroup className="">
                  <NumberFormat
                     customInput={Input}
                     value={state.amount}
                     prefix={"₦"}
                     thousandSeparator={true}
                     label="amount"
                     placeholder="₦"
                     errorMessage={{
                        requiredError: "amount cannot be empty",
                        validityError: "please enter a valid amount",
                     }}
                     isValid={REGEX.AMOUNT_VALIDATION.test(state.amount)}
                     onValueChange={(values) =>
                        handleNumberFormat(values, "amount", "formattedAmount")
                     }
                  />
               </FormGroup>
               <Select
                  lineSelect={true}
                  value={state.paymentPurpose}
                  name="paymentPurpose"
                  label={selectLabel || "purpose"}
                  dispatch={dispatch}
                  options={SelectOptions}
                  dropDownIcon={
                     <span className="font-xs font-semibold text-black-two">
                        select
                     </span>
                  }
               />
            </div>
            <Button size="WIDE" onClick={handleNext}>
               pay
            </Button>
         </div>
      </section>
   );
}
