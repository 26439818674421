import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { IoCheckmarkCircleOutline } from "react-icons/io5";

import useRequest from "utils/hooks/useRequest";
import { useGlobalModalContext } from "features/modal/global-modal.context";
import { signupConfirmation } from "features/auth/auth.service";

export default function useSignupConfirmation() {
   const { queryFn: signupFn, loading: signupLoading } =
      useRequest(signupConfirmation);

   const navigate = useNavigate();
   const [error, setError] = useState({});
   const { showModal, hideModal, MODAL_TYPES } = useGlobalModalContext();

   const handleNext = (userId) => {
      hideModal();
      navigate(`../email-verification/${userId}`);
   };

   const confirmSignup = ({ email, password }) => {
      const queryObj = { email, password };

      signupFn(queryObj, {
         onSuccess: ({ _id: userId }) => {
            showModal(MODAL_TYPES.SUCCESS, {
               title: "registration complete",
               message: "check your email for verification code",
               btnText: "verify account",
               modalPosition: "CENTER",
               bg: "WHITE",
               size: "THIN",
               showCloseBtn: false,
               hideOnBlur: false,
               icon: <IoCheckmarkCircleOutline />,
               btnOnClick: () => handleNext(userId),
            });
         },
         onError: (message) => {
            if (message.includes("has already verified email")) {
               setError({
                  message:
                     "You should not be here because this account has already been verified. Please",
                  link: "../signin",
                  linkText: "sign in",
               });
            } else {
               setError({
                  message: "Something went wrong, please try again",
               });
            }
         },
      });
   };

   return { confirmSignup, signupLoading, signupError: error };
}
