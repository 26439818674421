import SplashScreen from "features/ui/layout/splash-screen";
import PageTitle from "features/ui/layout/headers/page-title";

export default function AuthLayout({ children, subtitle }) {
   return (
      <section className="flex min-h-screen  items-stretch justify-center bg-white">
         <SplashScreen />
         <div className="left-margin flex w-full flex-col items-center justify-center bg-gray-200 p-10  md:max-w-[600px] md:self-center md:rounded-lg">
            <PageTitle
               title="transact with ledga"
               message={subtitle}
               variant="TERTIARY"
            />
            <div className="w-full  items-center justify-center border-2">
               {children}
            </div>
         </div>
      </section>
   );
}
