import { useEffect, useRef } from "react";
import { useWindowResize } from "utils/hooks/useWindowResize";

export default function NumPadInput({
   bg,
   pin,
   setPin,
   className,
   handleSubmit,
}) {
   const inputRef = useRef();
   const { width } = useWindowResize();

   useEffect(() => {
      if (inputRef) {
         inputRef?.current?.focus();
      }
   });

   const handlePinChange = (e) => {
      const { value } = e.target;
      if (pin.length <= 4) {
         setPin(value);
      }
   };

   //autofocus on input when blurred
   const handleBlur = () => {
      setTimeout(() => {
         inputRef?.current?.focus();
      }, 100);
   };

   const handleKeyDown = (e) => {
      if (e.keyCode === 13) {
         handleSubmit(pin);
      }
   };

   //styles
   //styles
   const getColor = (position) => {
      if (bg === "BLUE") {
         return pin.length > position ? "bg-accent-one" : " bg-black-four";
      } else {
         return pin.length > position ? "bg-accent-one" : "bg-black-four";
      }
   };

   const fieldStyle = `
      w-5 h-5
      rounded-2xl
      md:text-3xl
      md:bg-black-four 
      md:w-12 md:h-12
      md:rounded-full 
      md:flex md:justify-center md:items-center
   `;

   return (
      <div className={`${className} relative mb-10 md:mb-16`}>
         <input
            autoComplete="off"
            ref={inputRef}
            value={pin}
            name="pin"
            onChange={handlePinChange}
            onKeyDown={handleKeyDown}
            type="number"
            readOnly={width > 640 ? false : true}
            className="opacity-0"
            onBlur={handleBlur}
         />
         <div className="absolute top-0 left-2/4 mt-4 flex w-[250px] -translate-x-2/4 justify-between text-accent-one">
            <div className={`${fieldStyle} ${getColor(0)}`}>
               <span className="hidden md:block">{pin[0]}</span>
            </div>
            <div className={`${fieldStyle} ${getColor(1)}`}>
               <span className="hidden md:block">{pin[1]}</span>
            </div>
            <div className={`${fieldStyle} ${getColor(2)}`}>
               <span className="hidden md:block">{pin[2]}</span>
            </div>
            <div className={`${fieldStyle} ${getColor(3)}`}>
               <span className="hidden md:block">{pin[3]}</span>
            </div>
         </div>
      </div>
   );
}
