const storagePrefix = "ledga_awesome_";

export const getFromLS = (name) => {
   return JSON.parse(window.localStorage.getItem(`${storagePrefix}${name}`));
};

export const setToLS = (name, value) => {
   window.localStorage.setItem(
      `${storagePrefix}${name}`,
      JSON.stringify(value),
   );
};

export const clearLS = (name) => {
   window.localStorage.removeItem(`${storagePrefix}${name}`);
};
