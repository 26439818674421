import { useState } from "react";
import Button from "features/shared/forms/button";
import { IoArrowBack } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import PageTitle from "features/shared/layout/headers/page-title";
import useSWR from "swr";
import Spinner from "features/shared/feedbacks/spinner";
import FailModal from "features/shared/feedbacks/failure-modal";
import Input from "features/ui/forms/input";
import UserCard from "features/ui/cards/user-card";

import UserImage from "../../assets/icons/LedgaV2 Icons/Add User.svg";

export default function UserDetails({
   transferType,
   pageName,
   handleChange,
   state,
   dispatch,
   formValidation,
}) {
   const navigate = useNavigate();

   const [errorModal, setErrorModal] = useState(false);
   const [showSucccesModal, setShowSuccessModal] = useState(false);

   const setRecipientName = (value) => {
      dispatch({
         type: "UPDATE_INPUT",
         payload: {
            recipientName: value.name,
            recipientCode: value.unique_id,
            image: value.image,
         },
      });
   };

   const IdIsValid = state.id.length === 6;
   const { data, error, isValidating } = useSWR(
      IdIsValid ? `member/payments/${transferType}/verify/${state.id}` : null,
      {
         onSuccess: (data) => {
            setRecipientName(data);
            setShowSuccessModal(true);
         },
         onError: (err) => {
            err?.message.includes("account found")
               ? setRecipientName(err?.message)
               : setErrorModal(true);
         },
      },
   );
   const userLoading = (IdIsValid && !data && !error) || isValidating;

   return (
      <section className="flex min-h-screen flex-col items-center  justify-center bg-[#F3F3F3] px-10  md:ml-32 md:justify-start md:pt-10 lg:ml-48">
         {/* <Header variant={"SETTINGS"} /> */}
         <IoArrowBack
            onClick={() => navigate("../../../dashboard")}
            className="font- fixed top-5 left-4 w-12 cursor-pointer text-4xl text-accent-one md:hidden md:w-8"
         />
         <div className="container-alt2  md:w-[600px] md:items-center md:justify-center ">
            <PageTitle variant="PRIMARY" title={`Enter Receiver`} message="" />

            <div className="mb-16 flex flex-col gap-3 self-stretch ">
               {showSucccesModal && (
                  <div className="md:self-center">
                     <UserCard
                        code={data?.unique_id}
                        name={data?.name}
                        image={data.image}
                     />
                  </div>
               )}
               <Input
                  value={state.id.toUpperCase()}
                  name="id"
                  // label={`${transferType} id`}
                  label={`Unique id`}
                  onChange={handleChange}
                  autoComplete="off"
                  errorMessage={{
                     requiredError: "id must be 10 digits long",
                     validityError: "please enter a valid id",
                  }}
                  isValid={formValidation.id}
                  className="self-stretch justify-self-stretch"
               />

               {/* <Input
                  variant="LINE"
                  value={state.recipientName}
                  name="id"
                  label={`confirm ${transferType} name`}
                  disabled
                  readOnly
                  icon={
                     userLoading && <Spinner color="GREY" size="EXTRA_SMALL" />
                  }
                  iconPosition="RIGHT"
               /> */}
            </div>
            <Button size="WIDE" to={`../../dashboard/${pageName}/2`}>
               Next
            </Button>
         </div>
         <FailModal modalDisplay={errorModal} to={"../"} />
      </section>
   );
}
