export default function Checkbox({ label, value, handleChange }) {
   //styles
   const labelClass =
      "box-content self-start group relative pl-8 cursor-pointer text-sm";
   const spanClass =
      "peer-checked:bg-primary absolute left-0 top-1/2 -translate-y-1/2 h-7 w-7 border-2 rounded-sm border-primary";
   const spanClassAfter =
      "peer-checked:after:block peer-checked:after:border-white after:hidden after:absolute after:top-[5px] after:left-[3.5px] after:h-2 after:w-4  after:border-primary after:rounded-[2px]  after:border-l-2 after:border-b-2 after:border-r-0 after:border-t-0 after:-rotate-[45deg]";

   return (
      <label checked={true} htmlFor="checkbox" className={labelClass}>
         <input
            value={value}
            type="checkbox"
            id="checkbox"
            onChange={handleChange}
            className="peer h-0 w-0 cursor-pointer opacity-0"
         />
         <span className={`${spanClass} ${spanClassAfter}`}></span>
         <span>{label}</span>
      </label>
   );
}
