import ResetForm from "./reset-form.component";
import ResetEmailForm from "./email-form.component";
import OTPVerification from "features/misc/components/otp-verification";
import EmailSuccess from "./email-success.component";
import useResetPassword from "features/reset-password/reset-password.api";

export default function ResetPasswordComponent() {
   const {
      step,
      error,
      resetError,
      emailSubmitLoading,
      otpVerificationLoading,
      passwordResetLoading,
      handleOTPChange,
      handleNext,
      handleSubmit,
      handleEmailSubmit,
      handleVerification,
   } = useResetPassword();

   switch (step) {
      case 4:
         return (
            <ResetForm
               handleSubmit={handleSubmit}
               loading={passwordResetLoading}
            />
         );
      case 3:
         return (
            <OTPVerification
               verificationError={error}
               handleChange={handleOTPChange}
               handleVerification={handleVerification}
               verificationLoading={otpVerificationLoading}
            />
         );
      case 2:
         return <EmailSuccess btnOnClick={handleNext} />;

      default:
         return (
            <ResetEmailForm
               emailError={error}
               resetError={resetError}
               handleEmailSubmit={handleEmailSubmit}
               emailSubmitLoading={emailSubmitLoading}
            />
         );
   }
}
