import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

export default function Form({
   id,
   onSubmit,
   children,
   options,
   schema,
   resetFormError,
   className,
}) {
   const hookFormMethods = useForm({
      ...options,
      resolver: schema && yupResolver(schema),
   });

   return (
      <form
         id={id}
         className={className}
         onFocus={resetFormError}
         onSubmit={hookFormMethods.handleSubmit((data) =>
            onSubmit(data, hookFormMethods.reset),
         )}
      >
         {children({ ...hookFormMethods, Controller })}
      </form>
   );
}
