import Button from "../forms/button";
import { MdOutlineCancel } from "react-icons/md";
import ModalContainer from "../modals/modal-container";

export default function SuccessFeedbackModal({
   src,
   icon,
   title,
   message,
   btnText,
   to,
   href,
   btnOnClick,
   outsideOnClick,
   modalDisplay,
   modalPosition,
   toggleModal,
   children,
   cancelBtn,
   bg,
}) {
   if (!title) {
      return <></>;
   }

   return (
      <ModalContainer
         area="MEDIUM"
         role="alert"
         color={bg === "WHITE" ? bg : "BLUE"}
         modalDisplay={modalDisplay}
         toggleModal={toggleModal}
         modalPosition={modalPosition}
         outsideOnClick={outsideOnClick}
      >
         {cancelBtn && (
            <MdOutlineCancel
               fontSize={50}
               onClick={toggleModal}
               className="fixed top-6 right-6 hidden cursor-pointer md:block "
            />
         )}
         <div className="poppins-bold container justify-center py-20">
            {icon ? (
               <div className="mb-6 flex justify-center text-7xl md:text-8xl">
                  {icon}
               </div>
            ) : (
               <img
                  src={src || "/icons/carbon_task-complete.svg"}
                  alt="Icon"
                  className="mx-auto mb-4 w-28"
               />
            )}

            <h1 className="text-2xl font-bold capitalize text-black-one">
               {title}
            </h1>
            <p className="poppins-regular  mt-1 text-center text-black-two sm:text-base md:text-lg">
               {message}
            </p>

            {children && <div className="whitespace-pre-wrap">{children}</div>}

            <div className="mt-10">
               {btnText && (
                  <Button
                     to={to}
                     href={href}
                     onClick={btnOnClick}
                     size="WIDE"
                     color={bg === "WHITE" ? "BLUE" : "WHITE"}
                  >
                     {btnText}
                  </Button>
               )}
            </div>
         </div>
      </ModalContainer>
   );
}
