import { IoMdFingerPrint } from "react-icons/io";
import { MdOutlineKeyboardBackspace } from "react-icons/md";

export default function NumpadBtns({ className, pin, setPin }) {
   const numberList = Array.from(Array(9), (e, i) => i + 1);

   const handleClick = (e) => {
      if (pin.length < 4) {
         setPin((prev) => `${prev}${e}`);
      }
   };

   const handleDelete = () => {
      if (pin.length > 0) {
         setPin(`${pin.slice(0, -1)}`);
      }
   };

   //style
   const buttonStyle = `p-4 flex justify-center items-center text-2xl`;

   return (
      <div
         className={`${className} grid  min-h-[45vh] w-full max-w-[350px] grid-cols-3 text-3xl md:hidden`}
      >
         {numberList.map((e, i) => (
            <button
               key={i}
               className={`${buttonStyle} inter-bold`}
               onClick={() => handleClick(e.toString())}
            >
               {e}
            </button>
         ))}
         <button className={buttonStyle}>
            <div></div>
         </button>
         <button
            className={`${buttonStyle} inter-bold`}
            onClick={() => handleClick("0")}
         >
            0
         </button>
         <button className={buttonStyle} onClick={handleDelete}>
            <MdOutlineKeyboardBackspace />
         </button>
      </div>
   );
}
