import { useSWRConfig } from "swr";
import { BsQuestionCircleFill } from "react-icons/bs";

import ReceiptField from "./receipt-field";
import ReceiptDownload from "./receipt-download";
import ModalContainer from "features/modal/modal-container.component";
import * as NumberFormatter from "utils/lib/number-formatter";
import { useGlobalModalContext } from "features/modal/global-modal.context";
import UserDetailsFields from "./user-details-fields";
import { Link } from "react-router-dom";
import { FaAngleRight } from "react-icons/fa";
import { IoDownload } from "react-icons/io5";
import { MdCallMade } from "react-icons/md";
import { useReactToPrint } from "react-to-print";
import { useRef } from "react";
import jsPDF from "jspdf";

export default function TransactionReceipt(props) {
   const { isOpen, onClose } = props;

   const { hideModal } = useGlobalModalContext();
   const handleClose = () => {
      hideModal();
      onClose && onClose();
   };

   const ref = useRef(null);

   const handlePrint = useReactToPrint({
      content: () => ref.current,
      documentTitle: "doc.pdf",
      copyStyles: true,
      print: async (printIframe) => {
         const document = printIframe.contentDocument;
         if (document) {
            const html = document.getElementById("printing");
            get_PDF_in_base64(html);
         }
      },
   });

   return (
      <>
         <ModalContainer isOpen={isOpen} onClose={handleClose}>
            <ReceiptContent {...props} handleDownload={handlePrint} />
            <div
               ref={ref}
               style={{ display: "none" }}
               handleDownload={handlePrint}
            >
               <ReceiptDownload {...props} />
            </div>
         </ModalContainer>
      </>
   );
}

const ReceiptContent = (props) => {
   const {
      created, //
      amount,
      type,
      reference,
      sender,
      recipient,
      status,
      handleDownload,
   } = props;

   /*destructure from props  rather than directly so props can be passed whole into getReceipt function */
   const { cache } = useSWRConfig();
   const member = cache.get("member/account/info");
   const organization = member?.admin?.organization_details?.name;

   /*
    *student is gotten from cache because the global modal which
    *is responsible for rendering the receipt in most cases wraps
    *auth-context and as a result does not have access to useStudent()
    *exported from authContext.
    */

   const formattedDate = NumberFormatter.getFormattedDate(created);
   const formattedTime = NumberFormatter.getFormattedTime(created);
   const formattedAmount = NumberFormatter.getFormattedAmount(amount);

   return (
      <>
         <style jsx>{`
            ::-webkit-scrollbar {
               width: 8px;
               height: 8px;
               border-radius: 8px;
            }

            ::-webkit-scrollbar-track {
               background: inherit;
               border-radius: 8px;
            }

            ::-webkit-scrollbar-thumb {
               background: #888;
               border-radius: 5px;
            }

            ::-webkit-scrollbar-thumb:hover {
               background: #555;
            }
         `}</style>
         <section className="mx-auto flex max-h-[100vh] w-full max-w-[500px] items-center justify-center overflow-y-scroll pb-20 pt-20 md:max-h-[90vh] md:pb-0 md:pt-0">
            <div className="mx-auto w-full px-12 md:py-6">
               <h2 className="poppins-semibold text-[24px] text-black-one">
                  Transaction Details
               </h2>

               <div className="mb-[20px] flex w-full flex-col items-center rounded-[5px] bg-white px-[20px] py-[20px] shadow-lg shadow-[#F5F5F5]">
                  <div
                     className={`flex h-[50px] w-[50px] items-center justify-center rounded-full ${
                        member?.unique_id === sender.uniqueId
                           ? "bg-[#EDC3C4]"
                           : "bg-[#B5DEBE]"
                     } `}
                  >
                     <MdCallMade
                        className={`${
                           member?.unique_id === sender.uniqueId
                              ? " text-red-600"
                              : "rotate-180 text-green-400"
                        }`}
                     />
                  </div>
                  <p className="poppins-regular text-[16px] text-[#474747]">
                     {member?.unique_id === sender.uniqueId
                        ? "Debit"
                        : "Credit"}
                  </p>
                  <p className="poppins-semibold text-[24px] text-[#121212]">
                     {formattedAmount}
                  </p>
               </div>

               <div className="flex w-full flex-col rounded-[5px] bg-white px-[20px] py-[20px] shadow-lg shadow-[#F5F5F5]">
                  <div>
                     <p className="poppins-semibold text-black mb-[16px] text-[16px] text-black-two">
                        Payment Details
                     </p>
                  </div>
                  <ReceiptField label="organization" value={organization} />
                  <UserDetailsFields
                     name="Sender"
                     data={sender}
                     className=" my-[5px] flex flex-row justify-between"
                  />
                  <UserDetailsFields
                     name="Recipent"
                     data={recipient}
                     className="my-[5px] flex flex-row justify-between"
                  />
                  <ReceiptField label="transaction type" value={type} />
                  <ReceiptField
                     label="Payment time"
                     value={`${formattedTime} ${formattedDate}`}
                  />
                  <ReceiptField label="Total Payment" value={formattedAmount} />
                  <ReceiptField label="transaction status" value={status} />
                  <ReceiptField label="reference" value={reference} />
               </div>

               <Link
                  to="dashboard/settings/contact-us"
                  className="bg-[FCFDFF] mt-[20px] flex  flex-row items-center justify-between gap-2 rounded-md px-[20px] py-[20px] text-black-one shadow-lg shadow-[#F5F5F5]"
               >
                  <BsQuestionCircleFill
                     size={30}
                     className="text-black-three"
                  />
                  <div>
                     <p className="poppins-medium text-2xl text-[14px]">
                        Trouble With Your Payment?
                     </p>
                     <p className="poppins-regular text-[12px]">
                        Let us know know on help center now!
                     </p>
                  </div>
                  <FaAngleRight size={30} color="#3B16A7" />
               </Link>
               <div
                  onClick={handleDownload}
                  className="poppins-medium mt-[10px] flex cursor-pointer items-center justify-center gap-[10px] rounded-[10px] border-[2px] border-[#1358CC] px-[20px] py-[10px]"
               >
                  Get PDF Reciept <IoDownload />
               </div>
            </div>
         </section>
      </>
   );
};

export const get_PDF_in_base64 = async (htmldoc) => {
   if (htmldoc) {
      // const canvas = await html2canvas(htmldoc);
      const pdf = new jsPDF("p", "pt", "b5");
      pdf.setFont("ProductSans");
      console.log(htmldoc);
      pdf.html(htmldoc, {
         callback: function (doc) {
            // Save the PDF
            doc.save("sample-document.pdf");
         },
         // x: 15,
         // y: 15,
         // width: 140, //target width in the PDF document
         // windowWidth: 65,
      });
      // pdf.addImage(canvas.toDataURL("image/jpeg"), "JPEG", 0, 10, 80, 140);
      // const pdfBase64 = pdf.output("dataurlstring");
      // pdf.save("this is");
   }
};
