import * as yup from "yup";
import * as REGEX from "utils/constants/regex";

export const bankDetailsSchema = yup.object().shape({
   bank_name: yup
      .string()
      .matches(REGEX.NAME_VALIDATION, "please select a valid bank name")
      .required("bank name cannot be empty"),
   account_number: yup
      .string()
      .matches(
         REGEX.ACCOUNT_NUMBER_VALIDATION,
         "account number must be 10 digits long",
      )
      .required("account number cannot be empty")
      .typeError("account number must be 10 digits long"),
   account_name: yup
      .string()
      .matches(REGEX.NAME_VALIDATION, "please provide valid bank details")
      .required("bank name cannot be empty"),
});
