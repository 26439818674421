import * as yup from "yup";

import Button from "features/ui/forms/button";
import Form from "features/ui/forms/form";
import FormError from "features/ui/forms/form-error";
import Input from "features/ui/forms/input";

const schema = yup.object().shape({
   email: yup
      .string()
      .email("please enter a valid email")
      .required("email cannot be empty"),
});

export default function ResetPasswordEmailForm({
   emailError,
   resetError,
   handleEmailSubmit,
   emailSubmitLoading,
}) {
   const hookFormOptions = {
      defaultValue: {
         email: "",
      },
   };

   return (
      <div className="self-stretch">
         <FormError formError={emailError} />
         {/* <Form
            id="email-form"
            options={hookFormOptions}
            schema={schema}
            onSubmit={handleEmailSubmit}
            formError={emailError}
            resetFormError={resetError}
            className="my-24"
         >
            {({ register, formState: { errors, dirtyFields } }) => (
               <Input
                  label="email"
                  error={errors.email}
                  isEmpty={!dirtyFields.email}
                  registration={register("email")}
                  variant="LINE"
               />
            )}
         </Form> */}
         <div className="flex justify-center">
            <Button
               type="submit"
               size="WIDE"
               form="email-form"
               isLoading={emailSubmitLoading}
            >
               reset password
            </Button>
         </div>
      </div>
   );
}
