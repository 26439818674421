import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

export default function NavItem({
   isActive,
   icon,
   label,
   to,
   onClick,
   activeIcon,
   smallIcon,
}) {
   const [windowWidth, setWindowWidth] = useState(window.innerWidth);
   useEffect(() => {
      const handleResize = () => {
         setWindowWidth(window.innerWidth);
      };

      window.addEventListener("resize", handleResize);

      return () => {
         window.removeEventListener("resize", handleResize);
      };
   }, []);

   const iconToShow = windowWidth <= 768 && smallIcon ? smallIcon : activeIcon;
   const className = `flex gap-3 items-center 
      text-[14px] px-4 py-3
      hover:border-neutral-300 
      rounded-lg
      mt-[10px]
      duration-300
      font-semibold
      ${
         isActive
            ? `text-white font-semibold md:shadow-xl md:shadow-[#F5F5F5]  md:bg-accent-one`
            : "text-[#7E7E7E] dark:text-black-two"
      } `;

   if (onClick) {
      return (
         <Link
            to={to}
            onClick={onClick}
            className={`${className} ${isActive ? "" : ""}`}
         >
            {isActive ? iconToShow : icon}
            <span
               className={`${
                  label === "Logout" ? "text-black" : ""
               } hidden lg:block`}
            >
               {label}
            </span>
         </Link>
      );
   }
}
