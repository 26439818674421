import FundingActionsModal from "features/fund-account/components/funding-actions";
import LoadingModal from "features/ui/feedbacks/loading-modal";
import useMember from "api/hooks/useStudent";

export default function FundAccountActions() {
   const { member, getMemberLoading } = useMember();

   if (getMemberLoading) {
      return <LoadingModal isOpen={true} modalPosition="CENTER" />;
   }

   const funding = member.meta.funding;

   return (
      <section className="wrapper ">
         <div className="text-black mx-auto  h-full w-5/6 max-w-screen-sm px-10 py-10">
            <FundingActionsModal actionsIsOpen={true} funding={funding} />
         </div>
      </section>
   );
}
