import { classNames } from "utils/lib/getClassName";
import { useState, useRef, useEffect } from "react";

const INPUT_MAPS = {
   PRIMARY: "block border-2 border-primary p-[14px] rounded-lg",
   SECONDARY: "block border-2 border-primary p-4 rounded-lg bg-white",
   LINE: "block border-b-2 border-primary p-4 mr-2 rounded-none py-1 outline-none focus:border-neutral-600",
};

const LABEL_MAPS = {
   PRIMARY: "top-1/2 -translate-y-1/2 left-4 text-secondary-darker",
   SECONDARY: "top-1/2 -translate-y-1/2  left-4 text-secondary-darker",
   LINE: "-bottom-6 left-0",
};

const ERROR_LABEL_MAPS = {
   /*12px is added to -translate-y of label to factor in the increase in the 
   Input component height once error becomes active*/
   PRIMARY:
      "top-1/2 -translate-y-[calc(50%_+_12px)]  left-4 text-secondary-darker",
   SECONDARY:
      "top-1/2 -translate-y-[calc(50%_+_12px)]  left-4 text-secondary-darker",
   LINE: "",
};

const ACTIVE_MAPS = {
   PRIMARY: "top-1 text-xs left-4 text-accent-one",
   SECONDARY: "-top-3 px-1 left-3 bg-white",
   LINE: "-bottom-6 left-4 text-tertiary-darker",
};

const DISABLED_MAPS = {
   PRIMARY: "text-secondary-darker",
   SECONDARY: "text-secondary-darker",
   LINE: "text-secondary-darker",
};

const ERROR_MAPS = {
   PRIMARY: " ",
   SECONDARY: " ",
   LINE: "-top-4",
};

const ICON_POSITION_MAPS = {
   LEFT: "left-4",
   RIGHT: "right-4",
   LEFT_EDGE: "left-0",
   RIGHT_EDGE: "right-0",
   LEFT_OUTSIDE: "-left-6 top-8",
};

export default function Input({
   icon,
   label,
   value,
   type = "text",
   isValid,
   disabled = false,
   errorMessage,
   placeholder,
   showPassword,
   iconOnClick,
   iconPosition = "LEFT",
   variant = "PRIMARY",
   ...rest
}) {
   //TODO: extract variant constants into a util/helper file
   const input = useRef();
   const labelRef = useRef();
   const [error, setError] = useState("");
   const [isActivated, setIsActivated] = useState(false);
   const [toggleLabel, setToggleLabel] = useState(false);

   document.addEventListener("onautocomplete", function (e) {
      e.target.hasAttribute("autocompleted"); // true or false
      e.preventDefault();
   });

   useEffect(() => {
      if (isActivated) {
         if (value.length <= 0) {
            setError(errorMessage?.requiredError);
         } else if (value.length > 0 && !isValid) {
            setError(errorMessage?.validityError);
         } else {
            setError("");
         }
      }
   }, [isActivated, isValid, value, errorMessage]);

   useEffect(() => {
      if (value) {
         input.current.focus();
         setToggleLabel(true);
      }
   }, [value]);

   //styles
   const inputClass = classNames(
      "w-full text-sm md:text-base disabled:bg-white disabled:border-secondary",
      type === "password" && !showPassword
         ? "font-[900] tracking-[0.5em]"
         : "tracking-[.05em]",
      INPUT_MAPS[variant],
   );

   const labelClass = classNames(
      "capitalize absolute transition-all",
      toggleLabel
         ? ACTIVE_MAPS[variant]
         : error
         ? ERROR_LABEL_MAPS[variant]
         : LABEL_MAPS[variant],
      disabled && DISABLED_MAPS[variant],
   );

   const iconClass = classNames(
      "absolute z-50 text-slate-500 top-1/2 -translate-y-1/2",
      ICON_POSITION_MAPS[iconPosition],
   );

   const errorClass = classNames(
      "whitespace-pre-wrap text-[10px] pl-1 text-red-500 font-bolder",
      ERROR_MAPS[variant],
   );

   return (
      <label className="relative mb-5 block" ref={labelRef}>
         <input
            autoComplete="off"
            {...rest}
            type={type}
            ref={input}
            value={value}
            className={inputClass}
            disabled={disabled}
            placeholder={toggleLabel ? placeholder : ""}
            onFocus={() => {
               setToggleLabel(true);
            }}
            onBlur={() => {
               value.length > 0 && setIsActivated(true);
               setToggleLabel(input.current.value ? true : false);
            }}
         />
         <span className={labelClass}>{label}</span>
         <span className={iconClass} onClick={iconOnClick}>
            {icon}
         </span>
         <p className={errorClass}>{error}</p>
      </label>
   );
}

/*Structure */
//an input field typically has a border, a label positioned absolute and
//error message. LineInput in additon to these have an icon

/* Error Message*/
//when the input field is activated, the error variable can become displayed
//on the input page. the error variable can either be set to "requiredError"
//if the length of the field is empty, "validityError" when the field is not
//empty but the value is invalid or nothing when the field is valid

//also optional chaining is used in determining the value of the error variable
//because for some fields, only one error might be passed or no error as in the
//case of sign in password.
