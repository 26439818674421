import TableHeader from "features/shared/tables/table-header";
import TransactionsMonth from "./month";
import Spinner from "features/shared/feedbacks/spinner";
import FailModal from "features/shared/feedbacks/failure-modal";
import TransactionsRow from "./newmonth";

export default function TransactionsTable({
   toggleModal,
   transactionsData,
   transactionsError,
   transactionsLoading,
   refetchTransactions,
}) {
   return (
      <div className="mx-auto px-[20px] py-4 sm:max-w-lg sm:text-lg md:mt-6 md:max-w-3xl md:text-xl">
         {/* <TableHeader
            toggleModal={toggleModal}
            gridTemplateCols="grid-cols-custom-table-1"
            firstCol={"type"}
            otherCols={["date", "all"]}
            lastCol={"amount"}
         /> */}
         {transactionsError && !transactionsLoading ? (
            <FailModal modalDisplay={true} btnOnClick={refetchTransactions} />
         ) : transactionsLoading ? (
            <div className="my-32 flex items-center justify-center">
               <Spinner color="GREY" size="MEDIUM" />
            </div>
         ) : (
            Object.keys(transactionsData).map((day) => {
               return (
                  <section className="mb-10" key={day}>
                     <h2 className="poppins-semibold mb-[10px] text-[15px] font-semibold text-secondary-darker sm:pl-1 md:text-[20px] ">
                        {day}
                     </h2>
                     <div className="rounded-[10px] bg-white px-4 shadow-sm shadow-faintShadow-one">
                        {transactionsData[day].map((daily, index) => (
                           <TransactionsRow key={index} day={daily} />
                        ))}
                     </div>
                  </section>
               );
            })
         )}
      </div>
   );
}
