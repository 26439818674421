import NumberFormat from "react-number-format";

import Form from "features/ui/forms/form";
import Input from "features/ui/forms/input";
import Button from "features/ui/forms/button";
import Select from "features/ui/forms/select";
import FormGroup from "features/ui/forms/form-group";
import { getPersonalDetailsSchema } from "./setup.validation";
import { getClassOptions } from "utils/lib/getClasses";

export default function PersonalDetailsForm({ handleNext, classIsRequired }) {
   const formOptions = {
      mode: "onTouched",
      criteriaMode: "all",
      defaultValues: {
         firstname: "",
         lastname: "",
         class: "",
         address: "",
         date_of_birth: "",
      },
   };

   const personalDetailsSchema = getPersonalDetailsSchema(classIsRequired);
   const classOptions = classIsRequired && getClassOptions("University");

   return (
      <Form
         id="personalDetailsForm"
         options={formOptions}
         schema={personalDetailsSchema}
         onSubmit={(data) => handleNext(data)}
      >
         {({
            Controller,
            control,
            register,
            formState: { errors, dirtyFields },
         }) => {
            return (
               <FormGroup className="mb-10 self-stretch">
                  <Input
                     label="first name"
                     error={errors.firstname}
                     isEmpty={!dirtyFields.firstname}
                     registration={register("firstname")}
                  />
                  <Input
                     label="last name"
                     error={errors.lastname}
                     isEmpty={!dirtyFields.lastname}
                     registration={register("lastname")}
                  />

                  {classIsRequired && (
                     <FormGroup>
                        <Controller
                           control={control}
                           name="class"
                           defaultValue=""
                           render={({ field: { onChange, name, value } }) => (
                              <Select
                                 customInput={Input}
                                 isEmpty={value.length < 1}
                                 error={errors.inClass}
                                 label="class"
                                 options={classOptions}
                                 name={name}
                                 value={value}
                                 handleChange={(v) => {
                                    onChange(v);
                                 }}
                              />
                           )}
                        />
                     </FormGroup>
                  )}

                  <Input
                     label="address"
                     error={errors.address}
                     isEmpty={!dirtyFields.address}
                     registration={register("address")}
                  />
                  <Controller
                     control={control}
                     name="date_of_birth"
                     defaultValue=""
                     render={({ field: { onChange, name, value } }) => (
                        <NumberFormat
                           customInput={Input}
                           isEmpty={value.length < 1}
                           format="##/##/####"
                           mask={["d", "d", "m", "m", "y", "y", "y", "y"]}
                           error={errors.date_of_birth}
                           label="date of birth"
                           placeholder="dd/mm/yyyy"
                           name={name}
                           value={value}
                           onValueChange={(v) => {
                              onChange(v.formattedValue);
                           }}
                        />
                     )}
                  />

                  <div className="mt-4 flex justify-center">
                     <Button
                        size="WIDE"
                        type="submit"
                        form="personalDetailsForm"
                     >
                        next
                     </Button>
                  </div>
               </FormGroup>
            );
         }}
      </Form>
   );
}

//NumberFormat is used to format date of birth values as they come in.
//the Input is simply passed in as a prop value of the customInput prop of
//NumberFormat template in order for the styles to be inherited. All props
//relating to the originally designed Input is passed is passed together directly
//into NumberFormat
