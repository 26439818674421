import * as yup from "yup";
import * as REGEX from "utils/constants/regex";

export const signinSchema = yup.object().shape({
   uniqueId: yup
      .string()
      .matches(REGEX.UNIQUE_ID_VALIDATION, "please enter a valid unique id")
      .required("unique id cannot be empty"),
   password: yup.string().required("password cannot be empty"),
});

export const emailResetSchema = yup.object().shape({
   email: yup
      .string()
      .email("please enter a valid email")
      .required("email cannot be empty"),
});
export const signupSchema = yup.object().shape({
   email: yup
      .string()
      .email("please enter a valid email")
      .required("email cannot be empty"),
   password: yup
      .string()
      .required("password cannot be empty")
      .matches(/.{8,}/, "password cannot be less than 8 characters")
      .matches(/[0-9]/, "password must contain at least one digit")
      .matches(/[a-z]/, "password must contain at least one lowercase letter")
      .matches(/[A-Z]/, "password must contain at least one uppercase letter"),
   passwordConfirmation: yup
      .string()
      .oneOf([yup.ref("password"), null], "password does not match"),
});
