import SignupConfirmationForm from "features/auth/components/signup-confirmation-form";
import SetupLayout from "features/setup/setup-layout.component";

export default function SignupConfirmation() {
   return (
      <SetupLayout>
         <SignupConfirmationForm />
      </SetupLayout>
   );
}
