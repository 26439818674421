import jsPDF from "jspdf";
import "jspdf-autotable";
import { getIsoCurrencyFormat } from "utils/lib/number-formatter";

export default function getReceipt(transaction) {
   const doc = new jsPDF("p", "mm", [210, 140]);

   doc.autoTable({
      head: [
         [
            {
               content: ["Transaction Details"],
               colSpan: 2,
            },
         ],
      ],
      body: [
         ["Date:", { content: transaction.formattedDate }],
         ["Time:", transaction.formattedTime],
         ["Amount:", getIsoCurrencyFormat(transaction.amount)],
         ...(transaction.transaction_type === "funding"
            ? [
                 [
                    "Full Amount:",
                    getIsoCurrencyFormat(transaction.paid_from.originalAmount),
                 ],
                 ["Transaction Reference:", transaction.transaction_refernce],
              ]
            : []),
         ["Transaction Type:", transaction.transaction_type],

         [
            {
               content: "Sender",
               colSpan: 2,
               styles: {
                  cellPadding: { top: 10, right: 0, bottom: 3, left: 0 },
               },
            },
         ],
         ...(transaction.sender.id
            ? [["Sender Id:", transaction.sender.id]]
            : []),
         ...(transaction.sender.accountNumber
            ? [["Account Number:", transaction.sender.accountNumber]]
            : []),
         ...(transaction.sender.bankName
            ? [["Bank Name:", transaction.sender.bankName]]
            : []),

         [
            {
               content: "Recipient",
               colSpan: 2,
               styles: {
                  cellPadding: { top: 10, right: 0, bottom: 3, left: 0 },
               },
            },
         ],
         ...(transaction.recipient.id
            ? [["Recipient Id:", transaction.recipient.id]]
            : []),
         ...(transaction.recipient.accountNumber
            ? [["Account Number:", transaction.recipient.accountNumber]]
            : []),
         ...(transaction.recipient.bankName
            ? [["Bank Name:", transaction.recipient.bankName]]
            : []),
      ],

      theme: "grid",

      columnStyles: {
         1: { halign: "right" },
      },

      headStyles: {
         fontSize: 13,
         valign: "middle",
         textColor: "#fff",
         fillColor: "#2564eb",
         cellPadding: { top: 6, right: 3, bottom: 3, left: 3 },
      },

      bodyStyles: {
         fontSize: 11,
         textColor: "#2564eb",
         fillColor: "#fff",
         cellPadding: { top: 4, right: 2, bottom: 4, left: 2 },
         lineWidth: { top: 0, right: 0, bottom: 0.2, left: 0 },
      },
   });

   doc.save(`${transaction._id}.pdf`);
}
