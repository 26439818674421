import BankDetailsForm from "features/bank-details/bank-details-form.component";

import CardContainer from "features/ui/cards/card-container";
import EnterPassword from "../features/bank-details/enter-password.component";
import PageTitle from "features/ui/layout/headers/page-title";
// import ArrowBack from "features/ui/layout/nav/arrow-back";
import useUpdateBankDetails from "features/bank-details/update-bank-details.api";
import Header from "features/shared/layout/headers/dashboard-header";
import ArrowBack from "features/shared/layout/nav/arrow-back";

export default function SetBankDetailsModal() {
   const {
      navigate,
      handleClick,
      state,
      dispatch,
      hookFormMethods,
      updateLoading,
      handleSubmit,
      enterPasswordModal,
      setEnterPasswordModal,
   } = useUpdateBankDetails();

   return (
      <>
         <Header variant={"SETTINGS"} />
         <ArrowBack variant="SECONDARY" handleBack={() => navigate(-1)} />
         {/* <CardContainer> */}
         <div className="min-h-screen">
            <div className="container-alt  min-h-screen justify-center md:min-h-0 md:py-12">
               <ArrowBack handleBack={() => navigate(-1)} />
               <PageTitle
                  variant="SECONDARY"
                  title="Update Bank Details"
                  // message="Change payout account"
               />
               <BankDetailsForm
                  btnText="next"
                  onSubmit={handleClick}
                  hookFormMethods={hookFormMethods}
               />
            </div>
         </div>
         {/* </CardContainer> */}
         <EnterPassword
            state={state}
            dispatch={dispatch}
            loading={updateLoading}
            btnText="update account"
            handleSubmit={handleSubmit}
            isOpen={enterPasswordModal}
            onClose={() => setEnterPasswordModal(false)}
         />
      </>
   );
}
