import useSWR from "swr";
import { useEffect, useState } from "react";

import { getAllBillsUrl } from "api/services/bills.service";

export default function useBills(config) {
   const [outStandingBills, setOutStandingBills] = useState([]);
   const [sortedBills, setSortedBills] = useState([]);

   const { data: bills, error: getBillsError } = useSWR([
      getAllBillsUrl,
      config,
      "V2",
   ]);

   const { data: paidBills, error: paidBillsError } =
      useSWR("member/bills/paid");

   const getBillsLoading = !bills && !getBillsError;
   const getPaidbillsLoading = !paidBills && !paidBillsError;
   const getAllBillsLoading = getPaidbillsLoading || getBillsLoading;

   useEffect(() => {
      if (bills && paidBills) {
         const unpaid = bills.bills.filter(
            (bill) => !paidBills.some((b) => b.to === bill.unique_id),
         );
         const paid = bills.bills.filter((bill) =>
            paidBills.some((b) => b.to === bill.unique_id),
         );
         setSortedBills(paid);
         setOutStandingBills(unpaid);
      }
   }, [bills, paidBills]);

   return {
      bills: bills?.bills,
      paidBills,
      outStandingBills,
      sortedBills,
      getAllBillsError: getBillsError,
      getAllBillsLoading,
   };
}
