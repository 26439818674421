import { Link } from "react-router-dom";

import Alert from "features/ui/feedbacks/alert";

export default function FormError({ formError }) {
   return (
      <>
         {formError?.message && (
            <Alert>
               <div>
                  {formError.message}{" "}
                  <Link
                     to={formError.link ?? ""}
                     className="font-semibold text-accent-one underline hover:text-yellow-600"
                  >
                     {formError?.linkText}
                  </Link>
               </div>
            </Alert>
         )}
      </>
   );
}
