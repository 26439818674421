import { Link } from "react-router-dom";
import { classNames } from "utils/lib/getClassName";

const VARIANT_MAPS = {
   PRIMARY: "border-2 border-primary rounded-lg",
   SECONDARY: "border-none underline",
   TERTIARY: "border-none",
};

const COLOR_MAPS = (variant) => ({
   WHITE: "bg-white text-accent-one hover:bg-primary-lightest hover:text-lighter",
   BLUE: "bg-primary text-white hover:bg-primary-darker disabled:text-accent-one-lighter",
   RED: " bg-red-500 text-white hover:bg-red-600 border-none",

   BLUE_TEXT:
      variant === "TERTIARY"
         ? "bg-none text-accent-one hover:text-tertiary-darker"
         : undefined,
   YELLOW_TEXT:
      variant === "TERTIARY"
         ? "bg-none text-tertiary-darker hover:text-accent-one"
         : undefined,
});

const SIZE_MAPS = (variant) => ({
   ZERO: variant === "TERTIARY" ? "px-0 py-0 text-left" : undefined,
   TINY:
      variant === "TERTIARY" ? "text-[10px] px-4 py-1 md:text-xs" : undefined,
   SMALL: "text-sm px-5 py-2",
   NORMAL: "text-lg px-10 py-[10px]",
   WIDE: "text-lg py-[10px] w-[250px]",
   EXTRA_WIDE: "text-lg py-3 w-[300px]",
});

export default function Button({
   children,
   to,
   href,
   variant = "PRIMARY",
   color = "BLUE",
   size = "NORMAL",
   disabled = false,
   ...rest
}) {
   const className = classNames(
      "capitalize font-semibold flex poppins-semibold justify-center items-center",
      VARIANT_MAPS[variant],
      COLOR_MAPS(variant)[color],
      SIZE_MAPS(variant)[size],
   );

   // react-router link
   if (to) {
      return (
         <Link to={to}>
            <button disabled={disabled} {...rest} className={className}>
               {children}
            </button>
         </Link>
      );
   }

   //   a link
   if (href) {
      return (
         <a href={href} disabled={disabled} {...rest} className={className}>
            {children}
         </a>
      );
   }

   //   button
   return (
      <button disabled={disabled} {...rest} className={className}>
         {children}
      </button>
   );
}

//a button can be a react router link button, an html link button  or
//an html button depending on prop
//button also have variants with different colors and sizes. all the possible
//states of the button are determind by the corresponding MAP objects
