import { Fragment } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import useRequest from "utils/hooks/useRequest";
import NumPadModal from "features/shared/numpad/numpad-modal";
import FailModal from "features/shared/feedbacks/failure-modal";
import LoadingModal from "features/shared/feedbacks/loading-modal";
import { changeTransactionPin } from "api/services/settings.service";
import SuccessFeedbackModal from "features/shared/feedbacks/success-modal";

export default function ChangePin() {
   const navigate = useNavigate();

   //form states
   const [previousPin, setPreviousPin] = useState("");
   const [pin, setPin] = useState("");
   const [confirmationPin, setConfirmationPin] = useState("");

   //modal states
   const [previousPinModal, setPreviousPinModal] = useState(true);
   const [createPinModal, setCreatePinModal] = useState("");
   const [confirmPinModal, setConfirmPinModal] = useState("");
   const [successModal, setSuccessModal] = useState(false);
   const [failureModal, setFailureModal] = useState(false);

   //query functions
   const {
      queryFn: changePinFn,
      loading,
      error,
   } = useRequest(changeTransactionPin);

   //query callback functions
   const onError = () => {
      setFailureModal(true);
   };

   const onSuccess = () => {
      setConfirmPinModal(false);
      setSuccessModal(true);
      setPin("");
      setConfirmationPin("");
      setPreviousPin("");
   };

   //handle functions
   const handleNext = () => {
      setPreviousPinModal(false);
      setCreatePinModal(true);
   };
   const handleConfirm = () => {
      setCreatePinModal(false);
      setConfirmPinModal(true);
   };
   const outsideOnClick = () => navigate(-1);
   const handleSubmit = () => {
      if (pin === confirmationPin) {
         const queryObj = { old_pin: previousPin, new_pin: pin };
         changePinFn(queryObj, { onSuccess, onError });
      }
   };
   const handleError = () => {
      setCreatePinModal(false);
      setFailureModal(true);
   };

   return (
      <Fragment>
         <NumPadModal
            authNumpad
            pin={previousPin}
            setPin={setPreviousPin}
            title="enter previous pin"
            btnText="proceed"
            btnOnClick={handleNext}
            modalDisplay={previousPinModal}
            toggleModal={outsideOnClick}
            outsideOnClick={outsideOnClick}
            message={`enter your previous transaction pin to continue`}
         />
         <NumPadModal
            authNumpad
            pin={pin}
            setPin={setPin}
            title={`create transaction pin`}
            btnText="proceed"
            btnOnClick={handleConfirm}
            outsideOnClick={outsideOnClick}
            modalDisplay={createPinModal}
            toggleModal={outsideOnClick}
            message={`Set up your transaction pin for approving \n requests on your ledga`}
         />
         <NumPadModal
            authNumpad
            pin={confirmationPin}
            setPin={setConfirmationPin}
            title="confirm pin"
            message="input newly created pin"
            btnText="proceed"
            btnOnClick={handleSubmit}
            outsideOnClick={outsideOnClick}
            modalDisplay={confirmPinModal}
            toggleModal={() => setConfirmPinModal((prev) => !prev)}
         />
         <LoadingModal
            modalDisplay={loading}
            message="please wait while the pin is being set"
         />
         <SuccessFeedbackModal
            modalDisplay={successModal}
            src="/icons/arcticons_ok-payment.svg"
            title="pin created"
            message="You can now approve transactions securely"
            btnText="home"
            to="../../../dashboard"
            outsideOnClick={() => navigate(-1)}
            toggleModal={() => setSuccessModal((prev) => !prev)}
         />
         <FailModal
            title="pin creation failed"
            message={error}
            modalDisplay={failureModal}
            btnText="try again"
            btnOnClick={handleError}
         />
      </Fragment>
   );
}
