export default function getSorted(transactionData) {
   const sortedArr = sortByDate(transactionData);

   const reducedObj = sortedArr.reduce((r, i) => {
      const year = i._id.year;
      const month = i._id.month;
      const data = { [month]: i.docs };

      if (r.hasOwnProperty(year)) {
         return { ...r, [year]: [...r[year], data] };
      } else {
         return { ...r, [year]: [data] };
      }
   }, {});

   return reducedObj;
}

export const formatDataByDay = (data) => {
   const formattedData = {};
   data.forEach((item) => {
      const docs = item.docs;

      docs.forEach((doc) => {
         const date = new Date(doc.created);
         const formattedDate = date.toLocaleDateString("en-US", {
            year: "numeric",
            month: "long",
            day: "numeric",
         });

         if (!formattedData[formattedDate]) {
            formattedData[formattedDate] = [];
         }

         formattedData[formattedDate].push(doc);
      });
   });

   return formattedData;
};
export const sortByDate = (transactionData) => {
   return transactionData.sort((a, b) => {
      const aDate = new Date(a._id.year, a._id.month - 1);
      const bDate = new Date(b._id.year, b._id.month - 1);
      return bDate.getTime() - aDate.getTime();
   });
};
