import { useState } from "react";
import { BsCaretDownFill } from "react-icons/bs";
import { CgShapeCircle } from "react-icons/cg";

export default function Select({
   lineSelect,
   dropDownIcon,
   label,
   name,
   value,
   options,
   dispatch,
}) {
   const [isActive, setIsActive] = useState(false);

   const toggleIsActive = () => {
      setIsActive(!isActive);
   };

   const handleSelect = (option) => {
      dispatch({
         type: "updateSelect",
         payload: { name: name, value: option },
      });
      toggleIsActive();
   };

   //styles
   const selectBtnStyle = `relative capitalize text-slate-500 cursor-pointer`;
   const dropDownHeaderStyle = `${
      lineSelect ? "border-b-2" : "border-2 shadow-inner rounded-lg"
   } flex justify-between items-center border-blue-600 w-full py-2 px-3 text-sm dark:bg-transparent dark:border-neutral-600`;
   const dropDownStyle = `${
      isActive ? "block" : "hidden"
   } z-50 rounded-sm w-4/6 max-h-[220px] overflow-y-scroll border-2 bg-white right-0 absolute`;
   const optionStyle = `capitalize flex justify-between items-center p-2 text-accent-two`;

   return (
      <div className={selectBtnStyle}>
         <div className={dropDownHeaderStyle} onClick={toggleIsActive}>
            <p>{value ? value : label}</p>
            {dropDownIcon || (
               <BsCaretDownFill className="dark:text-black text-blue-600" />
            )}
         </div>

         <div className={dropDownStyle}>
            <ul>
               {options.map((option) => (
                  <li
                     key={Math.random()}
                     className={`${optionStyle} ${
                        value === option.name && "text-yellow-500"
                     }`}
                     onClick={() => handleSelect(option.name)}
                  >
                     <p>{option.name}</p>

                     {option.icon ? (
                        <img
                           src={`/icons/${option.icon}`}
                           alt={option.name}
                           className={`${
                              value === option.name
                                 ? "filter-yellow"
                                 : "filter-blue"
                           }`}
                        />
                     ) : (
                        <CgShapeCircle />
                     )}
                  </li>
               ))}
            </ul>
         </div>
      </div>
   );
}
