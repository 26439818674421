import Button from "../forms/button";
// import NumPadInput from "./pin-input";
import NumPadInput from "features/numpad-new/pin-input";
import NumpadHeader from "features/numpad-new/header";
import NumPadBtns from "./pin-buttons";
import { IoArrowBack } from "react-icons/io5";
import ModalContainer from "../modals/modal-container";
import { useWindowResize } from "utils/hooks/useWindowResize";
import UserCard from "features/ui/cards/user-card";

import UserImage from "../../../assets/icons/LedgaV2 Icons/Add User.svg";

export default function NumPadModal({
   title,
   pin,
   setPin,
   amount,
   receiver,
   message,
   btnText,
   background = "WHITE",
   btnOnClick,
   modalDisplay,
   toggleModal,
   togglePrevModal,
   modalPosition,
   authNumpad,
   outsideOnClick,
}) {
   //TODO: figure out relationship with modal and verification compolete components
   //TODO: figure out responsive layout
   //TODO: work on more responsive classes, work on variants

   //styles
   const messageClass = `
      ${amount && "order-2"} ${
      !authNumpad
         ? "md:-order-4  md:border-b-2 md:border-secondary-lighter"
         : "md:-oder-2"
   } 
   text-center text-xs poppins-regular whitespace-pre-wrap md:w-full md:text-lg md:p-2 text-black-three md:mb-10
   `;
   const titleClass = `
   ${amount && "order-3"} ${!authNumpad ? "md:oder-last mb-6" : "md:-order-3"} 
   text-center text-black-one poppins-bold font-semibold text-3xl whtespace-pre md:text-accent-one
   `;
   const transactionDetailsClass =
      "flex justify-between mb-2 items-center order-1 md:-order-3 flex-col";
   const detailClass = `${
      background === "BLUE"
         ? "bg-mainGrey-one text-accent-one"
         : "bg-mainGrey-one text-accent-one"
   } ${
      receiver ? "md:text-2xl" : "md:text-5xl"
   } flex items-center rounded-3xl font-semibold p-2 mx-2 md:bg-inherit md:text-secondary-darker`;

   const handleBack = () => {
      toggleModal();
      togglePrevModal();
   };

   const { width } = useWindowResize();
   return (
      <ModalContainer
         modalDisplay={modalDisplay}
         toggleModal={toggleModal}
         color={background}
         position={modalPosition}
         outsideOnClick={outsideOnClick}
         variant="NUMPAD"
         // area="CUSTOM"
      >
         <IoArrowBack
            onClick={handleBack}
            className="fixed top-12 left-6 w-12 cursor-pointer text-4xl md:hidden md:w-8"
         />
         <div className="flex min-h-[75vh] w-full flex-col items-center md:block md:min-h-0">
            <div className="order-1 flex flex-col items-center">
               {amount && (
                  <div className={transactionDetailsClass}>
                     {receiver && (
                        <NumpadHeader
                           title={title}
                           receiver={receiver.id}
                           message={message}
                           amount={amount}
                        />
                        // <div className="md:self-center">
                        //    <UserCard
                        //       code={receiver.id}
                        //       name={receiver.recipientName}
                        //       image={UserImage}
                        //       amount={amount}
                        //       variant="NORMAL"
                        //    />
                        // </div>
                     )}
                  </div>
               )}

               <p className={messageClass}>{message}</p>

               <NumPadInput
                  pin={pin}
                  setPin={setPin}
                  handleSubmit={btnOnClick}
                  background={"BLUE"}
                  className={`${amount && "order-4"} ${
                     !authNumpad
                        ? "md:-oder-2 md:mb-[100px]"
                        : "md:-order-4 md:mt-10"
                  }`}
               />
            </div>

            <NumPadBtns
               pin={pin}
               setPin={setPin}
               className={`order-2 md:hidden`}
            />
            <div className="md: order-3 mb-10 flex flex-col items-center">
               <Button
                  color={
                     background === "BLUE" && width < 769 ? "WHITE" : "BLUE"
                  }
                  onClick={btnOnClick}
               >
                  {btnText}
               </Button>
            </div>
         </div>
      </ModalContainer>
   );
}
