import VerificationInput from "react-verification-input";
import Button from "features/ui/forms/button";

export default function OTPVerification({
   verificationLoading,
   validChars = "a-z0-9",
   handleVerification,
   verificationError,
   handleChange,
}) {
   return (
      <div className="flex flex-col items-center justify-center">
         <h2 className="text-3xl font-bold text-primary">Verification Code</h2>

         <p className="my-5 text-center text-secondary-darkest md:text-sm">
            Input your six digit pin sent to your <br />
            sms and email to proceed
         </p>
         <div className="relative mb-10 flex justify-center">
            <VerificationInput
               length={6}
               autoFocus={true}
               validChars={validChars}
               placeholder=""
               onChange={(value) => handleChange(value)}
               classNames={{
                  // container: "bg-black",
                  // characterInactive: "bg-white",
                  character: "rounded-lg",
               }}
            />
            <span className="absolute -bottom-10 my-2 animate-pulse text-[13px] font-semibold text-red-700">
               {verificationError}
            </span>
         </div>

         <Button
            size="WIDE"
            disabled={verificationLoading}
            isLoading={verificationLoading}
            onClick={handleVerification}
         >
            verify
         </Button>
      </div>
   );
}
