import Button from "features/ui/forms/button";
import PageTitle from "features/ui/layout/headers/page-title";
import ArrowBack from "features/ui/layout/nav/arrow-back";
import ModalContainer from "features/modal/modal-container.component";
import AccountCard from "features/shared/forms/bank-card";

export default function BankDetailsDisplay({
   isOpen,
   onClose,
   bankDetails,
   handleNext,
}) {
   const { bankName, accountName, accountNumber } = bankDetails;
   return (
      <ModalContainer isOpen={isOpen} onClose={onClose}>
         <ArrowBack to={"../"} />
         <div className="container px-10 pt-32 md:py-16">
            <PageTitle
               variant="TERTIARY"
               title="withdraw"
               message="Confirm account payment should be made to"
            />
            <AccountCard
               bankName={bankName}
               accountNo={accountNumber}
               name={accountName}
            />
            <div className="mb-10">
               <Button
                  variant="TERTIARY"
                  color="BLACK_TEXT"
                  to="../../dashboard/settings/bank-details"
               >
                  change payout account
               </Button>
            </div>
            <Button size="WIDE" onClick={handleNext}>
               next
            </Button>
         </div>
      </ModalContainer>
   );
}
