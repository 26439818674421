import { getTransferDetails } from "utils/lib/getTransferDetails";
import { getFormattedAmount, getFormattedDate } from "./number-formatter";
import { getFormattedTime } from "./number-formatter";

export const getTransformedTransaction = (transactions, studentId) => {
   const isCreditTransaction = (transaction, studentId) => {
      const transactionType = transaction.transaction_type;
      return (
         transactionType === "funding" ||
         (transactionType === "transfer" && transaction.to === studentId)
      );
   };

   const transformedTransactions = transactions.map((month) => ({
      ...month,

      docs: month.docs.map((transaction) => {
         return {
            ...transaction,
            sender: getTransferDetails(transaction).sender,
            recipient: getTransferDetails(transaction).recipient,
            formattedDate: getFormattedDate(transaction.created),
            formattedTime: getFormattedTime(transaction.created),
            formattedAmount: getFormattedAmount(transaction.amount),
            originalAmount:
               transaction.transaction_type === "funding" &&
               getFormattedAmount(transaction.paid_from.originalAmount),
            isCreditTransaction: isCreditTransaction(transaction, studentId),
         };
      }),
   }));

   return transformedTransactions;
};
