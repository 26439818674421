import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { useFormData } from "utils/hooks/useFormData";
import useRequest from "utils/hooks/useRequest";
import { bankDetailsSchema } from "./bank-details.validation";
import { updateBankDetails } from "api/services/settings.service";
import { useGlobalModalContext } from "features/modal/global-modal.context";

export default function useUpdateBankDetails() {
   const navigate = useNavigate();
   const initialFormData = {
      account_number: "",
      bank_name: "",
      bank_code: "",
      account_name: "",
      password: "",
   };
   const [state, dispatch] = useFormData(initialFormData);
   const hookFormMethods = useForm({
      mode: "onTouched",
      criteriaMode: "all",
      defaultValues: {
         bank_name: "",
         bank_code: "",
         account_number: "",
         account_name: "",
      },
      resolver: yupResolver(bankDetailsSchema),
   });

   const { reset: resetBankForm } = hookFormMethods;

   const { showModal, MODAL_TYPES } = useGlobalModalContext();
   const [enterPasswordModal, setEnterPasswordModal] = useState(false);

   const { queryFn: updateFn, loading: updateLoading } =
      useRequest(updateBankDetails);

   const handleClick = (data) => {
      dispatch({ type: "UPDATE_INPUT", payload: data });
      setEnterPasswordModal(true);
   };

   const handleSubmit = () => {
      const queryObj = state;

      updateFn(queryObj, {
         onSuccess: () => {
            setEnterPasswordModal(false);
            showModal(MODAL_TYPES.SUCCESS, {
               src: "/icons/arcticons_ok-payment.svg",
               title: "success",
               message: "bank details have been updated",
               btnText: "home",
               btnOnClick: () => navigate(-1),
               onClose: () => navigate(-1),
            });
            resetBankForm();
            dispatch({ type: "CLEAN_SLATE", payload: initialFormData });
         },

         onError: (message) => {
            setEnterPasswordModal(false);
            const passwordIncorrect = message?.includes(
               "you entered is incorrect",
            );
            showModal(MODAL_TYPES.ERROR, {
               title: passwordIncorrect && "password incorrect",
               message: passwordIncorrect
                  ? message
                  : `bank details' update could not be completed\nplease check your network settings and try again`,
            });
            resetBankForm();
            dispatch({ type: "CLEAN_SLATE", payload: initialFormData });
         },
      });
   };

   return {
      navigate,
      handleClick,
      state,
      hookFormMethods,
      dispatch,
      updateLoading,
      handleSubmit,
      enterPasswordModal,
      setEnterPasswordModal,
   };
}
