import { Link } from "react-router-dom";
import { ReactComponent as ArrowForward } from "../../../src/assets/icons/LedgaV2 Icons/Arrow Complete.svg";

export default function LinkItemColored({
   className,
   icon,
   label,
   to,
   btnOnclick,
}) {
   return (
      <div
         className={`${className} poppins-medium text-black flex flex-row items-center justify-between border-none py-3 text-base capitalize sm:text-xl  lg:text-[22px] lg:font-normal`}
      >
         <div className="flex flex-row items-center justify-center gap-2">
            {icon}
            {to ? (
               <Link to={to}>{label}</Link>
            ) : (
               <button onClick={btnOnclick}>{label}</button>
            )}
         </div>

         <ArrowForward className="text-white" />
      </div>
   );
}
