import { useReducer } from "react";

export function useFormData(initialFormData) {
   let [state, dispatch] = useReducer((state, action) => {
      switch (action.type) {
         case "UPDATE_INPUT": {
            return {
               ...state,
               ...action.payload,
            };
         }

         case "TOGGLE_INPUT": {
            return {
               ...state,
               [action.fieldName]: !state[action.fieldName],
            };
         }

         case "CLEAN_SLATE": {
            return { ...action.payload };
         }

         case "updateInput": {
            return {
               ...state,
               [action.payload.name]: action.payload.value,
            };
         }

         case "updateSelect": {
            return { ...state, [action.payload.name]: action.payload.value };
         }
         default: {
            return { ...state };
         }
      }
   }, initialFormData);

   return [state, dispatch];
}
