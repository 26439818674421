import { axios } from "utils/lib/axios";
import { clearLS } from "utils/lib/storage";

export const signupConfirmation = async (queryObj) => {
   return axios.post("member/setup/confirm-password", queryObj);
};

export const verifyEmailOTP = async (queryObj) => {
   return axios.post("member/setup/verify-otp", queryObj);
};

export const signinWithEmailAndPassword = async (queryObj) => {
   return axios.post("member/login/id", queryObj);
};

export const getMemberUrl = () => "member/account/info";

export const signOut = () => {
   window.location.replace("/signin");
   clearLS("token");
};
