import NotFound from "pages/error-404";
import { useRoutes } from "react-router-dom";
import { protectedRoutes } from "./protected";
import { publicRoutes } from "./public";

export function ApiRoutes() {
   const commonRoutes = [{ path: "*", element: <NotFound /> }];

   const routes = [...protectedRoutes, ...publicRoutes, ...commonRoutes];

   const elements = useRoutes(routes);

   return <>{elements}</>;
}
