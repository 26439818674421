import { useState } from "react";

import NumpadHeader from "./header";
import NumPadInput from "./pin-input";
import NumPadBtns from "./pin-buttons";
import Button from "features/ui/forms/button";
import * as REGEX from "utils/constants/regex";
import ArrowBack from "features/ui/layout/nav/arrow-back";
import { useWindowResize } from "utils/hooks/useWindowResize";
import { getFormattedAmount } from "utils/lib/number-formatter";

export default function Numpad({
   title,
   amount,
   bg = "GREY",
   message,
   receiver,
   btnText,
   handleSubmit,
   handleBack,
   isValid,
   maxChar,
   submitLoading,
}) {
   const { width } = useWindowResize();
   const [pin, setPin] = useState("");
   const pinLength = Number(maxChar) ? Number(maxChar) : 4;
   const pinIsValid = isValid ? isValid : REGEX.PIN_VALIDATION.test(pin);
   const formattedAmount = getFormattedAmount(amount);

   const handleNumpadSubmit = () => {
      if (pinIsValid) {
         handleSubmit({ value: pin, error: {} });
      } else if (pin.length < pinLength) {
         handleSubmit({
            value: "",
            error: {
               message: `pin must be at lease ${pinLength} characters long`,
            },
         });
      } else {
         handleSubmit({ value: "", error: { message: `invalid pin` } });
      }
      setPin("");
   };

   return (
      <div
         className={`${
            bg === "BLUE"
               ? "bg-accent-one text-white"
               : "bg-white text-black-one "
         } min-h-screen md:min-h-0 md:bg-white`}
      >
         <div className="fixed bottom-6 flex min-h-[75%] w-full flex-col items-center md:relative md:bottom-0 md:block md:h-full md:min-h-0">
            {handleBack && (
               <div className="fixed top-6 left-4 sm:left-16 md:absolute md:-left-36 md:-top-4">
                  <ArrowBack
                     variant="PRIMARY"
                     handleBack={handleBack}
                     color={bg}
                  />
               </div>
            )}
            {/* the order of the title, message, amount and numpad
               inputs change with media query and is controlled by 
               flex order */}
            <div className="order-1 flex flex-col items-center">
               <NumpadHeader
                  bg={bg}
                  title={title}
                  receiver={receiver}
                  message={message}
                  amount={formattedAmount}
               />

               <NumPadInput
                  pin={pin}
                  handleSubmit={handleNumpadSubmit}
                  pinIsValid={pinIsValid}
                  setPin={setPin}
                  bg={bg}
                  className={`${amount && "order-4"} md:-oder-2 md:mb-[100px]`}
               />
            </div>

            <NumPadBtns
               pin={pin}
               setPin={setPin}
               className={`order-2 md:hidden`}
            />

            <div className="md: order-3 mb-10 flex flex-col items-center">
               <Button
                  size="WIDE"
                  isLoading={submitLoading}
                  disabled={!pinIsValid}
                  color={bg === "BLUE" && width < 769 ? "WHITE" : "BLUE"}
                  onClick={handleNumpadSubmit}
               >
                  {btnText}
               </Button>
            </div>
         </div>
      </div>
   );
}
