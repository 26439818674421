import * as yup from "yup";
import * as REGEX from "utils/constants/regex";

export const getPersonalDetailsSchema = (classIsRequired) =>
   yup.object().shape({
      firstname: yup
         .string()
         .required("first name cannot be empty")
         .matches(
            REGEX.ALPHANUMERIC_NAME_VALIDATION,
            "Please enter a valid first name",
         ),
      lastname: yup
         .string()
         .required("last name cannot be empty")
         .matches(
            REGEX.ALPHANUMERIC_NAME_VALIDATION,
            "Please enter a valid last name",
         ),

      class: yup.mixed().when([], {
         is: () => classIsRequired,
         then: yup.string().required("class cannot be empty"),
      }),

      address: yup.string().required("address cannot be empty"),
      date_of_birth: yup
         .string()
         .matches(
            REGEX.BIRTH_DATE_VALIDATION,
            "please enter a valid date of birth",
         )
         .required("student count cannot be empty"),
   });

export const createPasswordSchema = yup.object().shape({
   password: yup
      .string()
      .required("password cannot be empty")
      .matches(/.{8,}/, "password cannot be less than 8 characters")
      .matches(/[0-9]/, "password must contain at least one digit")
      .matches(/[a-z]/, "password must contain at least one lowercase letter")
      .matches(/[A-Z]/, "password must contain at least one uppercase letter"),
   passwordConfirmation: yup
      .string()
      .oneOf([yup.ref("password"), null], "password does not match"),
});
