import { useNavigate } from "react-router-dom";

import useRequest from "utils/hooks/useRequest";
import { useGlobalModalContext } from "features/modal/global-modal.context";
import { updateProfile } from "./setup.service";

export default function useUpdateProfile() {
   const navigate = useNavigate();
   const { showModal, hideModal, MODAL_TYPES } = useGlobalModalContext();
   const { queryFn: setupFn, loading: profileLoading } =
      useRequest(updateProfile);

   const handleFormSubmit = (queryObj) => {
      setupFn(queryObj, {
         onSuccess: (data) => {
            showModal(MODAL_TYPES.SUCCESS, {
               size: "THIN",
               modalPosition: "CENTER",
               title: "setup complete",
               message: `Profile Updated Successfully`,
               btnText: "Go Home",
               showCloseBtn: false,
               onClose: () => {
                  hideModal();
                  navigate("/dashboard");
               },
               btnOnClick: (error) => {
                  hideModal();
                  navigate("/dashboard");
               },
            });
         },

         onError: () => {
            showModal(MODAL_TYPES.ERROR, {
               modalPosition: "CENTER",
               btnOnClick: () => window.location.reload(),
            });
         },
      });
   };

   return {
      profileLoading,
      handleFormSubmit,
   };
}
