import useSWR from "swr";
import { useState, useEffect } from "react";

import { sortByDate } from "utils/lib/getSortedData";
import { getAllRequestsUrl } from "api/services/payments.service";
import { useGlobalModalContext } from "features/modal/global-modal.context";

export default function useAllRequests(queryParams) {
   const [requestsData, setRequestsData] = useState([]);
   const { showModal, MODAL_TYPES } = useGlobalModalContext();

   const {
      data: requests,
      error: requestsError,
      mutate: mutateRequests,
      isValidating: requestsMutating,
   } = useSWR([getAllRequestsUrl, queryParams], {
      onError: () => showModal(MODAL_TYPES.ERROR),
   });

   const requestsLoading = (!requests && !requestsError) || requestsMutating;

   useEffect(() => {
      if (requests) {
         setRequestsData(sortByDate([...requests]));
      }
   }, [requests]);

   return {
      requests,
      requestsData,
      requestsError,
      requestsLoading,
      mutateRequests,
      requestsMutating,
   };
}
