import { classNames } from "utils/lib/getClassName";

const TITLE_MAPS = {
   PRIMARY: "md:font-bold poppins-bold text-black-one md:text-black-one",
   SECONDARY: "md:font-normal poppins-bold text-black-one",
   TERTIARY: "poppins-bold text-black-one",
};

const MESSAGE_MAPS = {
   PRIMARY: "capitalize text-black-one poppins-regular font-semibold",
   SECONDARY: "text-gray-500 poppins-regular",
   TERTIARY: "capitalize poppins-regular text-black-three font-semibold",
};

export default function PageTitle({
   title,
   subtitle,
   message,
   className,
   variant = "PRIMARY",
}) {
   //styles
   const titleClass = classNames(
      "capitalize text-black text-2xl font-extrabold mb-1 md:text-4xl",
      TITLE_MAPS[variant],
   );
   const messageClass = classNames("text-lg md:text-xl", MESSAGE_MAPS[variant]);

   return (
      <div className={`${className} mb-10 self-stretch`}>
         <h1 className={titleClass}>{title}</h1>
         <h2 className={titleClass}>{subtitle}</h2>
         <p className={messageClass}>{message}</p>
      </div>
   );
}

//both h1 and h2 share the same titleClass because both serve the same
//purpose on the page, one is subtitle is passed when the Title is not the
//the first on the page, example when its on a modal which means it is technically
//the second title on the page and not the first
