import Numpad from "features/numpad/numpad.component";
import useCreatePin from "features/pin/create-pin.api";
import CardContainer from "features/ui/cards/card-container";

export default function CreatePin() {
   const {
      step,
      pin,
      pinConfirmation,
      setPin,
      setPinConfirmation,
      handleNext,
      handleSubmit,
   } = useCreatePin();

   switch (step) {
      case 2: {
         return (
            <>
               <CardContainer
                  area="MEDIUMV2"
                  position="CENTER"
                  variant="NUMPAD"
               >
                  <Numpad
                     bg="BLUE"
                     pin={pinConfirmation}
                     setPin={setPinConfirmation}
                     title="Confirm Pin"
                     btnText="next"
                     btnOnClick={handleSubmit}
                     message="input newly created pin"
                  />
               </CardContainer>
            </>
         );
      }
      default: {
         return (
            <CardContainer area="MEDIUMV2" position="CENTER" variant="NUMPAD">
               <Numpad
                  hideOnBlur={false}
                  pin={pin}
                  setPin={setPin}
                  title="Create Transaction Pin"
                  btnText="Proceed"
                  btnOnClick={handleNext}
                  message={`Set up your transaction pin for approving \n requests on your ledga`}
               />
            </CardContainer>
         );
      }
   }
}
