import { useParams } from "react-router-dom";
import Transfer from "features/transfer/transfer";

export default function PayMerchants() {
   //get the current step in the two part form
   const { step } = useParams();

   return (
      <>
         <Transfer
            step={Number(step)}
            transferType="merchant"
            pageName="pay-merchant"
         />
      </>
   );
}
