import { useState, useEffect } from "react";
import NumberFormat from "react-number-format";
import Header from "features/shared/layout/headers/dashboard-header";
import TransactionSummary from "features/dashboard/summary";
import PaymentHistory from "features/dashboard/payment-history";
import CreatePinModal from "features/setup/create-pin-modal";
import useTransactions from "features/transactions/transactions.api";
import getTransactionsSummary from "utils/lib/getTransactionsSummary";
import { Link } from "react-router-dom";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS } from "chart.js/auto";
import useMember from "api/hooks/useStudent";
import { RiBillFill } from "react-icons/ri";
import { BsCartFill, BsSendFill } from "react-icons/bs";
import { MdCallMade } from "react-icons/md";

const options = {
   responsive: true,
   maintainAspectRatio: false,
   plugins: {
      legend: {
         display: true,
         labels: {
            color: "#848484",
            font: {
               family: "Poppins", // Add your desired font here for legend labels
            },
         },
      },
      tooltip: {
         bodyFont: {
            family: "Poppins", // Add your desired font here for tooltip body
         },
         titleFont: {
            family: "Poppins", // Add your desired font here for tooltip title
         },
      },
   },
   // Other chart options...
};

export default function DashboardHome() {
   const [createPinModal, setCreatePinModal] = useState("");

   const { member } = useMember();

   useEffect(() => {
      if (member && !member.meta?.hasSetTransactionPin) {
         setCreatePinModal(true);
      }
   }, [member]);

   // const { fundings, payouts } = useTransactions();

   // const summaryData = getTransactionsSummary(payouts, fundings);

   return (
      <section className="wrapper">
         <Header
            variant="DASHBOARD"
            className="rounded-custom-b-lr-6xl py-8 md:rounded-none md:bg-white md:py-0 md:pt-28 md:text-slate-500"
         >
            {/* <div className="mx-auto table border-slate-400 pb-4 md:border-b-2">
               <p className="rounded-2xl  bg-blueSpectrum-base2 p-4 text-5xl font-semibold md:bg-white md:p-0 md:text-6xl lg:text-7xl">
                  <NumberFormat
                     value={member?.meta.balance}
                     displayType={"text"}
                     thousandSeparator={true}
                     prefix={"₦"}
                  />
               </p>
            </div> */}
            <div className="mt-20 table bg-white pb-4">
               <p className="poppins-semibold rounded-2xl text-5xl font-semibold text-accent-two md:bg-white md:p-0 md:text-6xl lg:text-7xl">
                  <span className="text-xl text-accent-three">₦</span>
                  <NumberFormat
                     value={member?.meta.balance}
                     displayType={"text"}
                     thousandSeparator={true}
                     // prefix={"₦"}
                  />
               </p>
               {/* <p className="text-center">total ledga bal: {earningsBal}</p> */}
            </div>
            {/* Withdraw and Fund Buttons */}
            <div className="text-accent-one-darker flex gap-5">
               <Link
                  to="/dashboard/accounts/withdraw"
                  className="flex h-[50px] items-center justify-start gap-2 rounded-3xl bg-accent-one p-2 px-6 font-semibold"
               >
                  <MdCallMade size={25} />

                  <span className="poppins-semibold text-white">Withdraw</span>
               </Link>
               <Link
                  to="/accounts/funding/create"
                  className="flex h-[50px] items-center justify-start gap-2 rounded-3xl bg-[#C4D5F2] p-2 px-6 font-semibold"
               >
                  <MdCallMade
                     size={25}
                     className="rotate-180 text-accent-one"
                  />
                  <span className="poppins-semibold text-accent-one">Fund</span>
               </Link>
            </div>
         </Header>
         <div className="mx-auto flex max-w-4xl flex-col">
            <div className="mt-6 flex w-[85%] flex-col gap-4 self-center">
               <p className="poppins-semibold text-xl text-black-one">
                  Quick Actions
               </p>
               <div className="poppins-semibold flex flex-wrap gap-5 text-sm text-black-one">
                  <Link
                     to="/dashboard/peer-transfer/1"
                     className="flex flex-col items-center"
                  >
                     <div className="flex h-[50px] w-[50px] items-center justify-center rounded-full bg-black-one md:h-[70px] md:w-[70px]">
                        <BsSendFill className="h-[30px] w-[30px] text-white md:h-[50px] md:w-[50px]" />
                     </div>

                     <p>Send Friend</p>
                  </Link>
                  <Link
                     to="/dashboard/pay-merchant/1"
                     className="flex flex-col items-center"
                  >
                     <div className="flex h-[50px] w-[50px] items-center justify-center rounded-full bg-black-one md:h-[70px] md:w-[70px]">
                        <BsCartFill className="h-[30px] w-[30px] text-white md:h-[50px] md:w-[50px]" />
                     </div>

                     <p>Pay Merchant</p>
                  </Link>
                  <Link
                     to="/dashboard/accounts/active"
                     className="flex flex-col items-center"
                  >
                     <div className="flex h-[50px] w-[50px] items-center justify-center rounded-full bg-black-one md:h-[70px] md:w-[70px]">
                        <RiBillFill className="h-[30px] w-[30px] text-white md:h-[50px] md:w-[50px]" />
                     </div>

                     <p>Pay Bill</p>
                  </Link>
               </div>
            </div>

            {/* <div className="my-[50px] flex h-[300px] w-full flex-row justify-center rounded-lg bg-white py-[20px]">
               <Doughnut
                  data={{
                     labels: ["funding", "payout"],
                     datasets: [
                        {
                           label: "amount",
                           data: [80, 20],
                           backgroundColor: ["#19B832", "#C52626"],
                           borderRadius: 15,
                        },
                     ],
                  }}
                  options={options}
               />
            </div> */}

            {/* <div className="lg:flex">
               <PaymentHistory
                  transactionType="payout"
                  transactionData={payouts}
                  className="lg:border-r-2 lg:border-secondary-lighter"
               />
               <PaymentHistory
                  paymentType="funding"
                  transactionData={fundings}
               />
            </div> */}
         </div>
         <CreatePinModal
            btnText="ok"
            modalDisplay={createPinModal}
            setModalDisplay={setCreatePinModal}
         />
      </section>
   );
}

// used dispaly:table to set width to content-width. mx-auto to center
