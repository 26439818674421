export const getOnchangeHandler = (dispatch, type) => {
   if (type === "number-format") {
      return (values, input, formattedInput) => {
         const { value, formattedValue } = values;
         dispatch({
            type: "UPDATE_INPUT",
            payload: { [input]: value, [formattedInput]: formattedValue },
         });
      };
   } else {
      return (e) => {
         const { name, value } = e.target;
         dispatch({ type: "UPDATE_INPUT", payload: { [name]: value } });
      };
   }
};
