import Input from "features/ui/forms/input";
import Button from "features/ui/forms/button";
import SearchInput from "features/ui/forms/search-input";
import Spinner from "features/ui/feedbacks/spinner";
import FormGroup from "features/ui/forms/form-group";
import useBankList from "./getBankList.api";
import useNubanName from "./getNubanName.api";
import { handleBankSelect } from "./bank-details.utils";

export default function BankDetailsForm({
   onSubmit,
   btnText,
   modalPosition,
   hookFormMethods,
}) {
   //useForm is called in the parents so they can have
   //access to reset method
   const {
      register,
      setValue,
      setFocus,
      handleSubmit,
      watch,
      clearErrors,
      formState: { errors, dirtyFields },
   } = hookFormMethods;

   const { bankNames, bankData, bankDataLoading } = useBankList(modalPosition);

   const { getNubanLoading } = useNubanName(
      watch,
      setValue,
      setFocus,
      modalPosition,
   );

   return (
      <>
         <form
            id="bankDetailsForm"
            onSubmit={handleSubmit(onSubmit)}
            className="mb-8 self-stretch"
         >
            <FormGroup>
               <SearchInput
                  name="bank_name"
                  label="bank name"
                  value={watch("bank_name")}
                  error={errors.bank_name}
                  isEmpty={!dirtyFields.bank_name}
                  iconPosition="RIGHT"
                  options={bankNames}
                  registration={register("bank_name")}
                  icon={bankDataLoading && <Spinner />}
                  handleSelect={(bankName) =>
                     handleBankSelect(bankName, bankData, setValue, clearErrors)
                  }
               />
               <Input
                  label="account number"
                  error={errors.account_number}
                  isEmpty={!dirtyFields.account_number}
                  registration={register("account_number")}
               />
               <Input
                  readOnly
                  label="account name"
                  iconPosition="RIGHT"
                  error={errors.account_name}
                  icon={getNubanLoading && <Spinner />}
                  registration={register("account_name")}
               />
               {/* <Input
                  label="bvn"
                  error={errors.bvn}
                  isEmpty={!dirtyFields.bvn}
                  registration={register("bvn")}
               /> */}
            </FormGroup>
         </form>
         <div className="flex justify-center">
            <Button
               size="WIDE"
               type="submit"
               form="bankDetailsForm"
               onClick={handleSubmit}
            >
               {btnText}
            </Button>
         </div>
      </>
   );
}
