import Input from "features/ui/forms/input";
import Button from "features/shared/forms/button";
import FormGroup from "features/shared/forms/form-group";
import ArrowBack from "features/shared/layout/nav/arrow-back";
import PageTitle from "features/shared/layout/headers/page-title";
import Checkbox from "features/shared/forms/checkbox";
import { useBillContext } from "../bills.context";

export default function BillTransactionDetails({ handleNext }) {
   const { state, dispatch } = useBillContext();

   return (
      <section className="wrapper">
         <div className="full-page container justify-center md:min-h-0 md:pt-16">
            <ArrowBack />
            <PageTitle
               variant="SECONDARY"
               title="transaction details"
               message="confirm transaction details"
            />
            {/* <img
               src="/icons/profile_icon.svg"
               alt="profile icon"
               className="filter-blue mb-10 block w-20 self-center"
            /> */}
            <div className="mb-20 self-stretch">
               <h2 className="mb-4 text-xl font-semibold text-black-one">
                  {state.billName}
               </h2>
               <FormGroup className="mb-[34px]">
                  <Input
                     disabled
                     name="id"
                     value={
                        state.makePartPayment
                           ? state.formattedMinimumAmount
                           : state.formattedAmount
                     }
                     label={state.makePartPayment ? "minimum amount" : "amount"}
                  />
               </FormGroup>
               <FormGroup className="mb-10">
                  <Input
                     disabled
                     variant="LINE"
                     name="id"
                     value={state.paymentPurpose}
                     label={"name"}
                  />
               </FormGroup>
               {state.partPaymentIsAllowed && (
                  <FormGroup className="mb-14">
                     <Checkbox
                        value={state.makePartPayment}
                        label="Make part payment of bill?"
                        handleChange={() =>
                           dispatch({
                              type: "TOGGLE_INPUT",
                              fieldName: "makePartPayment",
                           })
                        }
                     />
                  </FormGroup>
               )}
               <div className="flex justify-center">
                  <Button size="WIDE" onClick={handleNext}>
                     pay
                  </Button>
               </div>
            </div>
         </div>
      </section>
   );
}
