import { Link, useNavigate } from "react-router-dom";
import { getFormattedAmount } from "utils/lib/number-formatter";
import { handleBillDetails } from "../bills.utils";

import AccountIcon from "../../../assets/icons/LedgaV2 Icons/folder-gray.png";
import { useBillContext } from "../bills.context";

export default function BillCard({ bill }) {
   const navigate = useNavigate();
   const { dispatch } = useBillContext();

   const handleClick = () => {
      handleBillDetails(bill, dispatch);
      navigate("../pay-bill");
   };
   return (
      <div
         onClick={handleClick}
         className="shadow-opacity-80 group relative flex w-full max-w-full cursor-pointer flex-row items-center justify-start gap-4 rounded-xl bg-white px-5 text-black-three shadow-[0_4px_10px] shadow-faintShadow-two md:max-w-full md:px-10 md:py-[10px]"
      >
         {/* <Link
            to={bill.unique_id}
            className="after:absolute after:top-0 after:bottom-0 after:left-0 after:right-0"
         ></Link> */}
         <img
            src={AccountIcon}
            alt="icon"
            className="h-[40px] text-black-one"
         />
         <div className="h-[90px] w-[2px] bg-black-two"></div>
         <div className="text-start p-5 text-base font-semibold capitalize md:py-6 md:text-lg">
            <p className="poppins-bold text-[20px] text-black-one">
               <span className="poppins-medium text-[16px]">₦</span>
               {getFormattedAmount(bill.amount).slice(1)}
            </p>
            <p className="poppins-medium text-[16px] font-normal">
               {bill.title}
            </p>
            <p className="poppins-medium text-[16px] font-normal">
               {bill.unique_id}
            </p>
         </div>
      </div>
   );
}
