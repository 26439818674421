import Signin from "pages/signin";
import Welcome from "pages/welcome";
import SignupConfirmation from "pages/signup-confirmation";
import EmailVerification from "pages/email-verification";

export const publicRoutes = [
   { path: "signin", element: <Signin /> },
   { path: "welcome", element: <Welcome /> },
   { path: "signup-confirmation", element: <SignupConfirmation /> },
   { path: "email-verification/:userId", element: <EmailVerification /> },
];
