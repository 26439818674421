export default function RadioBtn({ options, selected, handleSelect }) {
   return (
      <div className="flex flex-wrap justify-center">
         {options.map((option) => {
            return (
               <div
                  className={`my-2 mx-1 min-w-[20px] cursor-pointer rounded-3xl border-2 border-primary px-4 py-2 text-center text-xs ${
                     selected === option
                        ? "bg-primary text-white"
                        : "bg-white text-accent-one"
                  } md:min-w-[80px] md:text-lg`}
                  onClick={() => handleSelect(option)}
               >
                  {option}
               </div>
            );
         })}
      </div>
   );
}
