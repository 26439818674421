import { useEffect } from "react";

import * as REGEX from "utils/constants/regex";
import useRequest from "utils/hooks/useRequest";
import { getNubanName } from "api/services/payments.service";
import { useGlobalModalContext } from "features/modal/global-modal.context";

export default function useNubanName(watch, setValue, setFocus, modalPosition) {
   const bankCode = watch("bank_code");
   const accountNumber = watch("account_number");

   const { showModal, MODAL_TYPES } = useGlobalModalContext();

   const { queryFn: getNubanFn, loading: getNubanLoading } =
      useRequest(getNubanName);

   useEffect(() => {
      if (bankCode > 1 && REGEX.ACCOUNT_NUMBER_VALIDATION.test(accountNumber)) {
         const queryObj = {
            account_number: accountNumber,
            bank_code: bankCode,
         };
         getNubanFn(queryObj, {
            onSuccess: (data) => {
               if (data.name.length < 1) {
                  showModal(MODAL_TYPES.ERROR, {
                     modalPosition: modalPosition,
                     title: "Beneficiary not found",
                     message: "Please check bank details and try again",
                  });
               } else {
                  //set value and focus manually to update readOnly input & toggle label
                  setValue("account_name", data.name);
                  setFocus("account_name");
               }
            },
            onError: () => {
               showModal(MODAL_TYPES.ERROR, {
                  modalPosition: modalPosition,
                  hideOnBlur: false,
                  btnOnClick: () => window.location.reload(),
               });
            },
         });
      }
   }, [
      getNubanFn,
      showModal,
      MODAL_TYPES,
      setFocus,
      setValue,
      bankCode,
      accountNumber,
      modalPosition,
   ]);

   return { getNubanLoading };
}
