import SplashScreen from "features/ui/layout/splash-screen";
import PageTitle from "features/ui/layout/headers/page-title";
import ArrowBack from "features/ui/layout/nav/arrow-back";

export default function SetupLayout({
   title,
   message,
   handleBack,
   first,
   children,
   screen,
}) {
   return (
      <section className="flex flex-col items-center justify-center bg-[#F3F3F3] px-[30px] pt-5 md:pl-[35%]">
         <SplashScreen />
         <div className="container flex min-h-screen items-stretch justify-center">
            <StepTracker screen={screen} length={2} />
            {handleBack && (
               <ArrowBack
                  first={first}
                  variant="TERTIARY"
                  handleBack={handleBack}
               />
            )}
            <PageTitle variant="TERTIARY" title={title} message={message} />
            {children}
         </div>
      </section>
   );
}

const StepTracker = ({ screen, length }) => {
   const arr = [...Array(length).keys()];
   return (
      <div className="absolute top-5 flex flex-row items-center gap-1  self-center">
         {arr.map((i) => (
            <>
               <div
                  className={`h-6 w-6 border-2 border-gray-400 ${
                     screen >= i + 1 && "bg-blue-500"
                  }  rounded-full`}
               ></div>
               {i + 1 < length ? (
                  <div className="h-[2px] w-10 bg-gray-400 md:w-20"></div>
               ) : null}
            </>
         ))}
      </div>
   );
};
