import { useState } from "react";
import Button from "features/shared/forms/button";
import WelcomeSlideOne from "features/welcome/slide-1";
import WelcomeSlideTwo from "features/welcome/slide-2";
import WelcomeSlideThree from "features/welcome/slide-3";
import FinalWelcomeSlide from "features/welcome/final-slide";

function Welcome() {
   const [screen, setScreen] = useState(0);

   switch (screen) {
      case 4:
         return <FinalWelcomeSlide setScreen={setScreen} />;

      case 3:
         return <WelcomeSlideThree setScreen={setScreen} />;

      case 2:
         return <WelcomeSlideTwo setScreen={setScreen} />;

      case 1:
         return <WelcomeSlideOne setScreen={setScreen} />;

      default:
         return (
            <section
               role="alert"
               className="flex min-h-screen w-full items-center justify-center bg-white py-10"
            >
               <div className="flex max-w-xs flex-col items-center p-2 text-center">
                  <img
                     src="/icons/social media _ social network, like, thumbs up, approve, man, people, complete 1Student.svg"
                     alt="Icon"
                     className="mx-auto mb-4 w-full md:w-48"
                  />

                  <h1 className="poppins-bold text-2xl font-bold capitalize text-blue-600">
                     Welcome to Ledga
                  </h1>

                  <p className="poppins-regular mt-1 text-stone-700 md:text-sm">
                     The financial tool that allows you to save pocket money,
                     make payment and send to friends within you organisation
                     environment. Free and seamless
                  </p>

                  <Button
                     onClick={() => setScreen((prev) => prev + 1)}
                     className="mt-16"
                  >
                     <span className="px-10">Get started</span>
                  </Button>
               </div>
            </section>
         );
   }
}

export default Welcome;
