import { Link, useLocation } from "react-router-dom";

export default function AccountsNav() {
   const { pathname } = useLocation();
   return (
      <div className="mt-10 flex w-full items-center justify-center gap-4 rounded-xl bg-white p-2 py-4 text-2xl font-semibold  text-black-three shadow-inner shadow-[#f5f5f5]">
         <NavLink
            isActive={pathname.includes("active")}
            href="/dashboard/accounts/active"
         >
            Unpaid
         </NavLink>
         <NavLink
            isActive={pathname.includes("paid")}
            href="/dashboard/accounts/paid"
         >
            Paid
         </NavLink>
      </div>
   );
}

const NavLink = ({ children, isActive, href }) => {
   return (
      <Link
         to={href}
         className={`flex items-center justify-center ${
            isActive ? "bg-black-one text-white" : "text-black-three"
         }  poppins-medium w-2/5 rounded-xl px-[10px] py-[3px] text-[16px]`}
      >
         {children}
      </Link>
   );
};
