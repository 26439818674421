import { useNavigate, useParams } from "react-router-dom";
import Transfer from "features/transfer/transfer";
import Header from "features/shared/layout/headers/dashboard-header";
import ArrowBack from "features/shared/layout/nav/arrow-back";

export default function PeerTransfer() {
   //get the current step in the two part form
   const { step } = useParams();
   const navigate = useNavigate();

   return (
      <>
         <div className="hidden md:block">
            <Header variant={"SETTINGS"} />
         </div>
         {/* <ArrowBack variant="SECONDARY" handleBack={() => navigate(-1)} /> */}

         <Transfer
            step={Number(step)}
            transferType="member"
            pageName="peer-transfer"
         />
      </>
   );
}
