import { ImSpinner2 } from "react-icons/im";
import { classNames } from "utils/lib/getClassName";

const SIZE_MAP = {
   LARGE: "text-6xl",
   MEDIUM: "text-4xl",
   SMALL: "text-[28px]",
};

const COLOR_MAPS = {
   BLUE: "text-accent-one",
   WHITE: "text-white",
   GREY: "text-secondary",
};

export default function Spinner({ size = "SMALL", color = "BLUE" }) {
   const className = classNames(
      "animate-spin",
      SIZE_MAP[size],
      COLOR_MAPS[color],
   );

   return (
      <div>
         <ImSpinner2 className={className} />
      </div>
   );
}
