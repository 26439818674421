import * as REGEX from "utils/constants/regex";
import Button from "features/ui/forms/button";
import NumPadInput from "./pin-input.component";
import NumPadBtns from "./pin-buttons.component";
import { TbArrowBigRightLine } from "react-icons/tb";
import ArrowBack from "features/ui/layout/nav/arrow-back";
import { useWindowResize } from "utils/hooks/useWindowResize";

export default function Numpad({
   title,
   pin,
   amount,
   bg = "BLUE",
   setPin,
   message,
   receiver,
   btnText,
   btnOnClick,
   handleBack,
   isValid,
}) {
   const { width } = useWindowResize();
   const pinIsValid = isValid ? isValid : REGEX.PIN_VALIDATION.test(pin);

   //styles
   const messageClass = `
      ${amount && "order-2"} 
      text-center text-xs whitespace-pre 
      md:mb-10 md:w-full
      md:text-lg md:text-secondary 
      md:text-left
   `;

   const titleClass = `
      mb-6
      ${amount && "order-3"} 
      text-center font-semibold text-2xl whtespace-pre 
      sm:text-3xl
      poppins-semi-bold
      md:w-full
      md:oder-last md:black-one
      md:text-left
      
   `;

   const transactionDetailsClass = `
      mb-2   
      flex justify-between items-center order-1
      md:-order-3`;

   const detailClass = `
      ${bg === "BLUE" ? "bg-white text-accent-one" : "bg-primary text-white"} 
      p-2 mx-2 
      flex items-center 
      rounded-3xl font-semibold 
      md:bg-inherit
      ${receiver ? "md:text-2xl" : "md:text-5xl"} md:text-secondary-darker`;

   return (
      <div
         className={`${
            bg === "BLUE"
               ? "bg-mainGrey-one text-black-one"
               : "bg-white text-accent-one"
         } poppins-semibold min-h-screen md:min-h-0 md:bg-white md:py-12 md:px-6`}
      >
         <div className="fixed bottom-6 flex min-h-[75%] w-full flex-col items-center md:relative md:bottom-0 md:block md:h-full md:min-h-0">
            {handleBack ? (
               <div className=" fixed top-6 left-4 sm:left-16 md:absolute md:-left-36 md:-top-4">
                  <ArrowBack
                     variant="SECONDARY"
                     handleBack={handleBack}
                     color={bg === "BLUE" ? "WHITE" : "BLUE"}
                  />
               </div>
            ) : null}
            <div className="order-1 flex flex-col items-center">
               {amount && (
                  <div className={transactionDetailsClass}>
                     <p className={`${detailClass}`}>{amount}</p>
                     {receiver && (
                        <>
                           <TbArrowBigRightLine
                              className={`${
                                 bg === "BLUE"
                                    ? "text-white"
                                    : "text-accent-one"
                              } text-3xl md:text-secondary`}
                           />
                           <p className={`${detailClass} text-sm`}>
                              {receiver}
                           </p>
                        </>
                     )}
                  </div>
               )}
               <h1 className={titleClass}>{title}</h1>
               <p className={`${messageClass} poppins-regular`}>{message}</p>

               <NumPadInput
                  pin={pin}
                  handleSubmit={btnOnClick}
                  setPin={setPin}
                  bg={bg}
                  className={`${amount && "order-4"} md:-oder-2  md:mb-[100px]`}
               />
            </div>

            <NumPadBtns
               pin={pin}
               setPin={setPin}
               className={` order-2 md:hidden`}
            />
            <div className="md: order-3 mb-10 flex flex-col items-center">
               <Button
                  size="EXTRA_WIDE"
                  disabled={!pinIsValid}
                  color={bg === "WHITE" && width < 769 ? "WHITE" : "BLUE"}
                  onClick={btnOnClick}
               >
                  {btnText}
               </Button>
            </div>
         </div>
      </div>
   );
}
