const classObj = {
   All: [{ name: "all" }],
   "Pre KG": [{ name: "Pre KG" }],
   KG: [{ name: "KG1" }, { name: "KG2" }, { name: "KG3" }],
   Primary: [
      { name: "PRY1" },
      { name: "PRY2" },
      { name: "PRY3" },
      { name: "PRY4" },
      { name: "PRY5" },
      { name: "PRY6" },
   ],
   Secondary: [
      { name: "JSS1" },
      { name: "JSS2" },
      { name: "JSS3" },
      { name: "SSS1" },
      { name: "SSS2" },
      { name: "SSS3" },
   ],
   College: [
      { name: "100lvl" },
      { name: "200lvl" },
      { name: "300lvl" },
      { name: "400lvl" },
      { name: "500lvl" },
      { name: "600lvl" },
   ],
   University: [
      { name: "Year 1" },
      { name: "Year 2" },
      { name: "Year 3" },
      { name: "Year 4" },
      { name: "Year 5" },
      { name: "Year 6" },
   ],
   Religious: [
      { name: "Spring Year 1" },
      { name: "Spring Year 2" },
      { name: "Spring Year 3" },
      { name: "Spring Year 4" },
      { name: "Fall Year 1" },
      { name: "Fall Year 2" },
      { name: "Fall Year 3" },
      { name: "Fall Year 4" },
      { name: "Marriage Class 20" },
      { name: "Marriage Class 21" },
   ],
};

export const getClassOptions = (institutionTypes) => {
   return classObj[institutionTypes];
};
