import { useState } from "react";

import useAllRequests from "features/requests/api/requests.api";
import { requestPayout } from "api/services/students.service";
import Button from "features/shared/forms/button";
import RequestsTable from "features/requests/components/table";
import Header from "features/shared/layout/headers/dashboard-header";
import FilterModal from "features/ui/modals/filter-modal";
import WithdrawModals from "features/withdraw/withdraw-modal";
import useStudent from "api/hooks/useStudent";

export default function Requests() {
   const [queryParams, setQueryParams] = useState("");
   const [withdrawModal, setWithdrawModal] = useState(false);
   const [filterModalIsOpen, setFilterModalIsOpen] = useState(false);
   const { ledgerBalance, studentBankDetails } = useStudent();

   const filterOptions = ["all", "pending", "success", "canceled"];

   // prettier-ignore
   const { 
      requestsData, 
      requestsLoading, 
      mutateRequests 
   } = useAllRequests(queryParams);

   const handleFilter = ({ option }) => {
      option === "all"
         ? setQueryParams("")
         : setQueryParams(`?status=${option}`);
   };

   return (
      <>
         <section className="wrapper">
            {/* <Header
               className="mb-2 pt-6 md:border-none md:bg-white md:text-slate-500"
               title="requests"
               settingsUrl="../settings"
            /> */}
            <Header settingsUrl="../settings" title="Requests" />
            <RequestsTable
               requestsData={requestsData}
               requestsLoading={requestsLoading}
               showFilterModal={() => setFilterModalIsOpen(true)}
            />
            <div className="fixed-btn">
               <Button onClick={() => setWithdrawModal(true)}>
                  request payout
               </Button>
            </div>
         </section>

         <FilterModal
            defaultValue="all"
            options={filterOptions}
            handleSubmit={handleFilter}
            isOpen={filterModalIsOpen}
            onClose={() => setFilterModalIsOpen((prev) => !prev)}
         />
         <WithdrawModals
            modalDisplay={withdrawModal}
            setModalDisplay={setWithdrawModal}
            mutateFn={mutateRequests}
            withdrawService={requestPayout}
            balance={ledgerBalance}
            bankDetails={studentBankDetails}
         />
      </>
   );
}
