import Button from "../forms/button";
import VerificationInput from "react-verification-input";
import ModalContainer from "features/shared/modals/modal-container";
import Spinner from "../feedbacks/spinner";

export default function VerificationModal({
   loading,
   setValue,
   dispatch,
   modalDisplay,
   modalPosition,
   placeholder = "·",
   validChars = "a-z0-9",
   handleVerification,
   errorMessage,
   setErrorMessage,
   outsideOnClick,
}) {
   //reset error message and update input
   const handleChange = (value) => {
      setErrorMessage("");
      setValue && setValue(value);
      dispatch && dispatch({ name: "updateInput", value });
   };

   return (
      <>
         <ModalContainer
            modalDisplay={modalDisplay}
            modalPosition={modalPosition}
            outsideOnClick={outsideOnClick}
         >
            <div className="container  justify-center md:py-12">
               <div className="relative mb-10 flex justify-center ">
                  <VerificationInput
                     length={6}
                     autoFocus={true}
                     validChars={validChars}
                     placeholder={placeholder}
                     onChange={(value) => handleChange(value)}
                  />
                  <span className="absolute -bottom-10 my-2 animate-pulse text-[13px] font-semibold text-red-700">
                     {errorMessage}
                  </span>
               </div>

               <h2 className="poppins-bold text-3xl font-bold text-accent-one">
                  Verification Code
               </h2>

               <p className="poppins-regular my-5 text-center text-black-three md:text-sm">
                  Input your six digit pin sent to your <br />
                  sms and email to proceed
               </p>
               <Button
                  size="WIDE"
                  disabled={loading}
                  onClick={handleVerification}
               >
                  {loading ? <Spinner color="WHITE" /> : "verify"}
               </Button>
            </div>
         </ModalContainer>
      </>
   );
}
