import ArrowBack from "features/ui/layout/nav/arrow-back";
import PageTitle from "features/ui/layout/headers/page-title";
import ModalContainer from "features/modal/modal-container.component";
import ResetPasswordComponent from "./reset-password.component";

export default function ResetPasswordModal({
   isOpen,
   modalPosition,
   handleClose,
}) {
   return (
      <ModalContainer
         isOpen={isOpen}
         modalPosition={modalPosition}
         onClose={handleClose}
      >
         <ArrowBack handleBack={handleClose} />
         <div className="container mt-24  min-h-[500px] justify-center bg-white text-blue-600 md:mt-0 md:py-14">
            <PageTitle variant="TERTIARY" subtitle="reset password" />
            <ResetPasswordComponent />
         </div>
      </ModalContainer>
   );
}
