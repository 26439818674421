import Form from "features/ui/forms/form";
import Input from "features/ui/forms/input";
import Button from "features/ui/forms/button";
import FormGroup from "features/ui/forms/form-group";
import Spinner from "features/ui/feedbacks/spinner";
import FormError from "features/ui/forms/form-error";
import { signinSchema } from "../auth.validation";

export default function SigninForm({
   signin,
   signinLoading,
   signinError,
   resetError,
   showResetPasswordModal,
}) {
   const formOptions = {
      mode: "onTouched",
      criteriaMode: "all",
      defaultValues: {
         uniqueId: "",
         password: "",
      },
   };

   return (
      <>
         <FormError formError={signinError} />
         <Form
            id="signinform"
            options={formOptions}
            schema={signinSchema}
            className="mb-20"
            onSubmit={signin}
            formError={signinError}
            resetFormError={resetError}
         >
            {({ register, formState: { errors, dirtyFields } }) => {
               return (
                  <>
                     <FormGroup className="mb-20">
                        <Input
                           label="Unique Id"
                           error={errors.uniqueId}
                           isEmpty={!dirtyFields.uniqueId}
                           registration={register("uniqueId")}
                        />

                        <Input
                           type="password"
                           label="password"
                           error={errors.password}
                           isEmpty={!dirtyFields.password}
                           registration={register("password")}
                        />
                     </FormGroup>
                  </>
               );
            }}
         </Form>
         <div className="mt-12 flex items-center justify-between">
            <Button type="submit" disabled={signinLoading} form="signinform">
               <span className="border-box flex h-5 w-16 items-center justify-center md:h-6 md:w-20">
                  {signinLoading ? (
                     <Spinner color="WHITE" />
                  ) : (
                     <span className="text-sm md:text-base">sign in</span>
                  )}
               </span>
            </Button>

            <Button
               size="ZERO"
               variant="TERTIARY"
               color="BLUE_TEXT"
               onClick={showResetPasswordModal}
               disabled={signinLoading}
            >
               forgot password?
            </Button>
         </div>
      </>
   );
}
