import { useEffect } from "react";
import { useImageUpload } from "utils/hooks/useImageUpload";

import UserIcon from "../../../assets/icons/LedgaV2 Icons/Profile.png";
import UploadFileIcon from "../../../assets/icons/LedgaV2 Icons/Paper Upload.svg";
import MoonLoader from "react-spinners/MoonLoader";

export default function ImageUpload({
   label,
   errorMessage,
   onSuccess,
   successLoading,
   onLoading,
   variant = "FILE",
}) {
   // generate uniqueIds to link label to input otherwise conflicts might ensue
   // when the component is used more than once in a page
   const uniqueId = `file-input-${label.split(" ").join("-")}`;
   const {
      onChange,
      loading: imgUploading,
      fileName,
      thumbnailUrl,
      imgUrl,
   } = useImageUpload(onSuccess);

   const updateLoading = imgUploading || successLoading;

   useEffect(() => {
      if (updateLoading && onLoading) {
         onLoading();
      }
   }, [updateLoading, onLoading]);

   return (
      <div className=" flex w-full flex-col items-center">
         <input
            accept="image/*"
            className="hidden"
            id={uniqueId}
            onChange={onChange}
            type="file"
         />
         {variant === "FILE" ? (
            <FileImage
               uniqueId={uniqueId}
               errorMessage={errorMessage}
               fileName={fileName}
               loading={updateLoading}
               thumbnailUrl={thumbnailUrl}
               label={label}
            />
         ) : (
            <ProfileImage
               uniqueId={uniqueId}
               errorMessage={errorMessage}
               fileName={fileName}
               loading={updateLoading}
               imgUrl={imgUrl}
               label={label}
            />
         )}
      </div>
   );
}

const FileImage = ({
   uniqueId,
   errorMessage,
   fileName,
   loading,
   thumbnailUrl,
   label,
}) => {
   return (
      <>
         <label
            htmlFor={uniqueId}
            className="flex w-[60px] cursor-pointer flex-col "
         >
            <img
               src={thumbnailUrl ? thumbnailUrl : UploadFileIcon}
               alt="upload"
            />
            <span
               className={"inline-block w-32 truncate text-xs text-yellow-600"}
            >
               {loading ? "loading..." : errorMessage ? "error" : fileName}
            </span>
         </label>
         <span className="capitalize text-gray-400">{label}</span>
      </>
   );
};

const ProfileImage = ({
   uniqueId,
   errorMessage,
   fileName,
   loading,
   imgUrl,
   label,
}) => {
   return (
      <label
         htmlFor={uniqueId}
         className="flex h-[170px] w-[170px] cursor-pointer flex-col items-center justify-center"
      >
         {loading ? (
            <MoonLoader
               size={100}
               color={"#2563eb"}
               loading={loading}
               speedMultiplier={0.5}
            />
         ) : (
            <img
               src={imgUrl ? imgUrl : UserIcon}
               alt="profile"
               className="mb-4 h-full w-full cursor-pointer rounded-[50%]  object-cover"
            />
         )}
         <span className="capitalize text-primary">{label}</span>
      </label>
   );
};
