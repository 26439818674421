import ModalContainer from "../modals/modal-container";

export default function LoadingModal({ message, modalDisplay, modalPosition }) {
   //TODO: add loading icon

   return (
      <ModalContainer
         area="THIN"
         modalDisplay={modalDisplay}
         position={modalPosition}
      >
         <div className="container justify-center py-20">
            <img
               src="/icons/tools _ time, hourglass, timer, deadline, stopwatch, people 1Student.svg"
               alt="loading"
               className="block w-full max-w-xs"
            />
            <p className="poppins-regular text-center text-black-three">
               {message}
            </p>
         </div>
      </ModalContainer>
   );
}
