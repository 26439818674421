import { TbArrowBigRightLine } from "react-icons/tb";

export default function NumpadHeader({ amount, receiver, title, message, bg }) {
   //styles
   const messageClass = `
      ${amount && "order-2"} 
      text-center text-xs whitespace-pre 
      md:-order-4  
      md:mb-10 md:p-2 md:w-full
      md:text-lg md:text-secondary 
      md:border-b-2 md:border-secondary-lighter
      poppins-regular
   `;

   const titleClass = `
      mb-6
      ${amount && "order-3"} 
      text-center font-semibold poppins-bold text-2xl whtespace-pre 
      sm:text-3xl
      md:oder-last md:text-accent-one
   `;

   const transactionDetailsClass = `
      mb-2   
      flex justify-between items-center order-1
      md:-order-3`;

   const detailClass = `
      ${
         bg === "BLUE" ? "bg-white text-accent-one" : "bg-accent-one text-white"
      } 
      p-2 mx-2 
      inter-bold
      flex items-center 
      rounded-3xl font-semibold 
      md:bg-inherit
      ${receiver ? "md:text-2xl" : "md:text-5xl"} md:text-secondary-darker`;

   return (
      <>
         {amount && (
            <div className={transactionDetailsClass}>
               <p className={`${detailClass}`}>{amount}</p>
               {receiver && (
                  <>
                     <TbArrowBigRightLine
                        className={`${
                           bg === "BLUE" ? "text-white" : "text-accent-one"
                        } text-3xl md:text-secondary`}
                     />
                     <p className={`${detailClass} text-sm`}>{receiver}</p>
                  </>
               )}
            </div>
         )}
         <h1 className={titleClass}>{title}</h1>
         <p className={messageClass}>{message}</p>
      </>
   );
}
