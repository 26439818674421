import { useState } from "react";
import { useNavigate } from "react-router-dom";
// import Input from "features/shared/forms/input";
import Button from "features/shared/forms/button";
import PageTitle from "features/shared/layout/headers/page-title";
import EmailSuccess from "../../shared/feedbacks/email-success";
import FormGroup from "features/shared/forms/form-group";
import ArrowBack from "features/shared/layout/nav/arrow-back";
// import Header from "features/shared/layout/headers/dashboard-header";
import Input from "features/ui/forms/input";
import Header from "features/shared/layout/headers/dashboard-header";

export default function ContactUs() {
   const navigate = useNavigate();
   const [name, setName] = useState("");
   const [email, setEmail] = useState("");
   const [body, setBody] = useState("");

   const [successModal, setSuccessModal] = useState(false);
   const toggleSuccessModal = (value) => setSuccessModal(value);

   const handleSubmit = () => {
      toggleSuccessModal(true);
      setTimeout(() => {
         navigate("../../../dashboard");
      }, 3000);
      return () => clearTimeout;
   };

   return (
      <section className="wrapper min-h-screen bg-[#F3F3F3]">
         <div className="hidden text-white md:block">
            <Header variant={"SETTINGS"} />
         </div>
         <div className="container-alt  pt-16">
            <ArrowBack variant="SECONDARY" handleBack={() => navigate(-1)} />
            <PageTitle
               variant="SECONDARY"
               title="Send Email"
               message="How can we help you?"
            />

            <form
               action="mailto:hello@ledgafinance.com"
               method="post"
               className="mb-4 w-full "
            >
               <FormGroup className="mb-5">
                  <Input
                     value={name}
                     // variant="LINE"
                     type="text"
                     name="name"
                     label="name"
                     onChange={(e) => setName(e.target.value)}
                  />
                  <Input
                     value={email}
                     // variant="LINE"
                     type="text"
                     name="email"
                     label="email"
                     onChange={(e) => setEmail(e.target.value)}
                  />
               </FormGroup>
               <label>
                  Feedback:
                  <textarea
                     value={body}
                     name="feedback"
                     rows="5"
                     cols="35"
                     className="w-full rounded-lg p-4"
                     onChange={(e) => setBody(e.target.value)}
                     placeholder="Enter your feedback or issue"
                  >
                     {body}
                  </textarea>
               </label>
            </form>
            <FormGroup className="flex w-full justify-between">
               <Button disabled={body.length < 3} onClick={handleSubmit}>
                  send
               </Button>
               <Button
                  disabled={body.length < 3}
                  size="ZERO"
                  variant="TERTIARY"
                  color="BLUE_TEXT"
                  onClick={() => setBody("")}
               >
                  reset
               </Button>
            </FormGroup>
            <EmailSuccess
               successModal={successModal}
               toggleSuccessModal={toggleSuccessModal}
               modalPosition="CENTER"
               outsideOnClick={() =>
                  body.length > 3 && navigate("../../../dashboard")
               }
            />
         </div>
      </section>
   );
}
