import { BsFilter } from "react-icons/bs";

import RequestsTableData from "./table-data";
import TableRow from "features/shared/tables/table-row";
import TableCol from "features/shared/tables/table-col";
import Spinner from "features/shared/feedbacks/spinner";

export default function RequestsTable({
   requestsData,
   requestsLoading,
   showFilterModal,
}) {
   //styles
   const wrapper = `
      mx-auto 
      w-5/6 
      max-w-sm 
      text-base 
      sm:max-w-lg 
      sm:text-lg  
      md:max-w-3xl 
      md:text-xl
   `;
   const tableHeader = `
      text-secondary-lighter 
      text-sm 
      font-medium
      mb-6 
      w-full 
      mx-auto 
      max-w-screen-md
   `;

   return (
      <div className={wrapper}>
         <div className={tableHeader}>
            <TableRow
               gridTemplateCols="grid-cols-3 sm:grid-cols-4"
               rowTypeHeader={true}
            >
               <TableCol className="cursor-pointer">
                  <BsFilter fontSize={25} onClick={showFilterModal} />
                  <p className="ml-2 hidden sm:block">id</p>
                  <p className="ml-2 sm:hidden">date</p>
               </TableCol>
               <TableCol className="hidden justify-center sm:flex">
                  date
               </TableCol>
               <TableCol className="justify-center">amount</TableCol>
               <TableCol className="mr-3 justify-end">status</TableCol>
            </TableRow>
         </div>

         {requestsLoading ? (
            <div className="my-32 flex items-center justify-center">
               <Spinner color="GREY" size="MEDIUM" />
            </div>
         ) : (
            requestsData?.map((e, i, arr) => {
               const thisYear = e._id.year;
               const lastYear = arr[i - 1]?._id.year;
               const isNewYear = i === 0 || thisYear !== lastYear;

               return (
                  <RequestsTableData
                     year={isNewYear && thisYear}
                     data={e.docs}
                     month={e._id.month}
                     key={`${e._id.year}${e._id.month}`}
                  />
               );
            })
         )}
      </div>
   );
}
