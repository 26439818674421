import { Link } from "react-router-dom";
import { BiUser, BiUserCheck, BiUserCircle } from "react-icons/bi";
import useMember from "api/hooks/useStudent";
import { RiNotification3Fill } from "react-icons/ri";
import { classNames } from "utils/lib/getClassName";
import ArrowBack from "../nav/arrow-back";

const VARIANT_MAPS = {
   PRIMARY:
      "mb-2 pt-6 md:border-none bg-white  md:bg-white md:text-slate-500 md:mx-auto md:max-w-[85%] lg:max-w-screen-md",
   SECONDARY:
      "w-full py-10 bg-white rounded-custom-b-lr-6xl mb-4 md:rounded-2xl md:mb-0",
   DASHBOARD:
      "py-10 dark:bg-white bg-white  shadow-[#F5F5F5] shadow-[0_1px_8px] shadow-opacity-100 bg-white flex flex-col items-start md:px-[250px] px-[30px] md:text-slate-500 md:py-5  ",
   TRANSACTIONS:
      "text-black py-[20px] px-[20px] md:px-[200px] pr-[40px] bg-black/0 md:bg-white",
   BILL: "w-full px-[20px] flex flex-col items-center justify-center items-start align-start py-10 bg-[#1358CC] h-[360px] rounded-custom-b-lr-bill mb-4 md:rounded-2xl md:mb-0",
   SAVINGS:
      "w-full px-[20px] flex flex-col items-center justify-center items-start align-start py-10 bg-none h-fit pt-[80px] rounded-custom-b-lr-bill md:rounded-2xl md:mb-0",
   SETTINGS:
      "py-2 dark:bg-white h-[70px] px-[20px] flex flex-col items-start rounded-lg md:px-[250px] px-[30px] md:rounded-none text-black md:py-5",
   REQUESTS:
      "py-10 px-[20px] dark:bg-white  flex flex-col items-start rounded-lg md:px-[250px] px-[30px] md:rounded-none text-black md:py-5",
   ACCOUNTS:
      "py-10 w-full px-[20px] dark:bg-white  flex flex-col items-start rounded-lg md:px-[250px] px-[30px] md:rounded-none text-black md:py-5",
   OTHERS:
      "w-full px-[20px] flex flex-col items-center justify-center items-start align-start py-10 bg-none h-fit pt-[80px] rounded-custom-b-lr-bill md:rounded-2xl md:mb-0",
};

const variantOptions = ["TRANSACTIONS", "REQUEST", "SETTINGS", "ACCOUNTS"];

export default function Header({ variant, settingsUrl, title, children, to }) {
   const { memberId } = useMember();
   //styles
   const headerClass = classNames(`text-white`, VARIANT_MAPS[variant]);

   const titleClass = `text-black-one  text-[24px] md:text-[40px] md:pl-0 md:border-none lg:text-4xl`;

   return (
      <header className={headerClass}>
         <div className="poppins-semibold flex w-full flex-row  items-center justify-between self-stretch  ">
            {variant === "DASHBOARD" ? (
               <div className=" absolute top-6  left-6 flex w-fit  items-center gap-2 md:left-80">
                  <Link to={settingsUrl || "../settings"}>
                     <img
                        alt="user profile"
                        className="border- h-[30px] w-[30px] rounded-[50%] border border-white"
                     />
                  </Link>
                  <p className="poppins-regular text-xs leading-[14px] text-black-one">
                     Welcome,
                     <br />
                     ORGANIZATION ID{" "}
                     <span className="poppins-bold mt-[2px]  block w-fit rounded-sm bg-accent-one px-[2px] py-[2px] text-[10px]  text-white">
                        {memberId}
                     </span>
                  </p>
               </div>
            ) : (
               !variantOptions.includes(variant) && (
                  <ArrowBack
                     color={
                        variant === "SAVINGS" || variant === "OTHERS"
                           ? "BLACK"
                           : "WHITE"
                     }
                     variant="PRIMARY"
                     to={to}
                  />
               )
            )}

            <h1 className={title ? titleClass : "hidden"}>{title}</h1>
            {variant !== "OTHERS" && (
               <RiNotification3Fill
                  size={30}
                  className={`${
                     variant === "DASHBOARD" || variant === "SAVINGS"
                        ? "text-black-one"
                        : "text-white"
                  } absolute top-6 right-6 w-12`}
               />
            )}
         </div>
         {children}
      </header>
   );
}
