import WelcomeSlideParentWrapper from "./slide-parent-wrapper";

export default function FinalWelcomeSlide({ setScreen }) {
   return (
      <WelcomeSlideParentWrapper
         url="/icons/accounts _ user, users, profile, account, man, people, website, browser, webpage 1Student.svg"
         text="Ledga offers you a great deal of flexibility in saving pocket money and making payments like never before."
         secondaryText="Now, let's setup your account"
         isFinalSlide={true}
         screen={4}
         setScreen={setScreen}
      />
   );
}
