import AccountsCard from "./accounts-card";
import Spinner from "features/shared/feedbacks/spinner";

export default function BillsList({ bills, billsLoading, title, paid }) {
   return (
      <div className="mb-6 self-stretch">
         <h2 className="mb-3 text-center text-xl font-semibold capitalize text-accent-one md:text-left md:text-2xl">
            {title}
         </h2>
         <div
            className={`${
               paid ? "text-secondary" : "text-accent-one"
            } grid grid-cols-1 justify-items-center gap-3 md:grid-cols-2 lg:gap-6`}
         >
            {billsLoading && <Spinner />}
            {bills?.map((e) => (
               <AccountsCard key={e.unique_id} data={e} paid={paid} />
            ))}
         </div>
      </div>
   );
}
