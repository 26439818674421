import Form from "features/ui/forms/form";
import Input from "features/ui/forms/input";
import Button from "features/ui/forms/button";
import FormGroup from "features/ui/forms/form-group";
import { signupSchema } from "../auth.validation";
import useSignupConfirmation from "../api/signup-confirmation";
import FormError from "features/ui/forms/form-error";
import Spinner from "features/ui/feedbacks/spinner";

const ErrorComponent = ({ message }) => {
   const errorClass = `whitespace-pre-wrap text-xs pl-1 text-red-500 font-bolder -mt-5 mb-5`;
   return (
      <p className={message && errorClass} role="alert" aria-label={message}>
         {message}
      </p>
   );
};

export default function SignupConfirmationForm() {
   const { confirmSignup, signupLoading, signupError } =
      useSignupConfirmation();

   const hookFormOptions = {
      mode: "onTouched",
      criteriaMode: "all",
      defaultValues: {
         email: "",
         password: "",
         passwordConfirmation: "",
      },
   };
   return (
      <div>
         <FormError formError={signupError} />
         <Form
            schema={signupSchema}
            id="signupConfirmation"
            options={hookFormOptions}
            onSubmit={confirmSignup}
         >
            {({ register, getValues, formState: { errors, dirtyFields } }) => {
               return (
                  <>
                     <FormGroup>
                        <Input
                           label="email"
                           error={errors.email}
                           isEmpty={!dirtyFields.email}
                           registration={register("email")}
                           variant="PRIMARYV2"
                        />

                        <Input
                           type="password"
                           label="password"
                           isEmpty={!dirtyFields.password}
                           registration={register("password")}
                           variant="PRIMARYV2"
                        />
                        <div>
                           {errors?.password?.type === "required" ? (
                              <ErrorComponent
                                 message={errors?.password?.message}
                              />
                           ) : (
                              []
                                 .concat(errors?.password?.types?.matches)
                                 .map((errorMessage, i) => (
                                    <ErrorComponent
                                       key={"errorMessage" + i}
                                       message={errorMessage}
                                    />
                                 ))
                           )}
                           {/*concat into an empty array because error.matches can
                              either return a string or an array */}
                        </div>
                     </FormGroup>

                     <FormGroup>
                        <Input
                           type="password"
                           label="password confirmation"
                           isEmpty={!dirtyFields?.passwordConfirmation}
                           registration={register("passwordConfirmation")}
                           variant="PRIMARYV2"
                        />
                        {getValues("password") !==
                           getValues("passwordConfirmation") && (
                           <ErrorComponent
                              message={errors?.passwordConfirmation?.message}
                           />
                        )}
                     </FormGroup>
                  </>
               );
            }}
         </Form>
         <div className="flex justify-center">
            <Button
               type="submit"
               disabled={signupLoading}
               form="signupConfirmation"
            >
               <span className="border-box flex h-5 w-16 items-center justify-center md:h-6 md:w-20">
                  {signupLoading ? (
                     <Spinner color="WHITE" />
                  ) : (
                     <span className="text-sm md:text-base">next</span>
                  )}
               </span>
            </Button>
         </div>
      </div>
   );
}
