import { useState, createContext, useContext, useEffect } from "react";

import { useLocation } from "react-router-dom";
import SuccessModal from "./success-modal.component";
import NumPadModal from "features/numpad/numpad-modal.component";
import ErrorModal from "./error-modal.component";
import LoadingModal from "features/ui/feedbacks/loading-modal";
import IDCreatedModal from "features/ui/feedbacks/id-created-modal";
import TransactionReceipt from "features/ui/receipt/transaction-receipt";
import ResetPasswordModal from "features/reset-password/reset-password.component";

export const MODAL_TYPES = {
   ERROR: "ERROR MODAL",
   NUMPAD: "NUMPAD MODAL",
   LOADING: "LOADING",
   SUCCESS: "SUCCESS MODAL",
   ID_CREATED: "ID CREATED",
   RECEIPT: "TRANSACTION RECEIPT",
   RESET_PASSWORD: "RESET PASSWORD MODAL",
};

const MODAL_COMPONENTS = {
   [MODAL_TYPES.ERROR]: ErrorModal,
   [MODAL_TYPES.NUMPAD]: NumPadModal,
   [MODAL_TYPES.LOADING]: LoadingModal,
   [MODAL_TYPES.SUCCESS]: SuccessModal,
   [MODAL_TYPES.ID_CREATED]: IDCreatedModal,
   [MODAL_TYPES.RECEIPT]: TransactionReceipt,
   [MODAL_TYPES.RESET_PASSWORD]: ResetPasswordModal,
};

const initialState = {
   showModal: () => {},
   hideModal: () => {},
   store: {},
   MODAL_TYPES: {},
};

//global modal context is created and consumed
//in this file alone in the useGlobalModalContext hoook

const GlobalModalContext = createContext(initialState);
export const useGlobalModalContext = () => useContext(GlobalModalContext);
export const GlobalModalProvider = ({ children }) => {
   const [store, setStore] = useState();
   const { modalType, modalProps } = store || {};

   /* overflow hidden to prevent scrolling on show*/
   const showModal = (modalType, modalProps) => {
      setStore({ modalType, modalProps });
      document.body.style.overflow = "hidden";
   };

   const hideModal = () => {
      setStore({ modalType: null, modalProps: {} });
      document.body.style.overflow = "visible";
   };

   const renderComponent = () => {
      const ModalComponent = MODAL_COMPONENTS[modalType];
      if (!modalType || !ModalComponent) {
         return null;
      }
      return <ModalComponent id="global-modal" {...modalProps} isOpen={true} />;
   };

   return (
      <GlobalModalContext.Provider
         value={{ store, showModal, hideModal, MODAL_TYPES }}
      >
         <RouteChangeHandler hideModal={hideModal} />
         {renderComponent()}
         {children}
      </GlobalModalContext.Provider>
   );
};

const RouteChangeHandler = ({ hideModal }) => {
   const location = useLocation();

   useEffect(() => {
      hideModal();
   }, [location]);
};
