import useAllRequests from "./requests.api";
import useRequest from "utils/hooks/useRequest";
import { MdOutlineCancel } from "react-icons/md";
import { cancelPayoutRequest } from "api/services/payments.service";
import { useGlobalModalContext } from "features/modal/global-modal.context";

export default function useRequestActions(requestId, closeCanceModal) {
   const { mutateRequests } = useAllRequests();
   const { showModal, hideModal, MODAL_TYPES } = useGlobalModalContext();
   const { queryFn: cancelPayoutFn, loading } = useRequest(cancelPayoutRequest);

   //handle functions
   const cancelRequest = () => {
      const queryObj = { transaction_id: requestId };
      cancelPayoutFn(queryObj, {
         onSuccess: () => {
            mutateRequests();
            closeCanceModal();
            showModal(MODAL_TYPES.SUCCESS, {
               title: "request canceled",
               message: "Your payout request has been canceled successfully",
               icon: <MdOutlineCancel />,
               btnText: "ok",
               btnOnClick: hideModal,
            });
         },

         onError: () => {
            closeCanceModal();
            showModal(MODAL_TYPES.ERROR, {
               message: `payout request could not be canceled\nplease check your network settings and try again`,
            });
         },
      });
   };

   return { cancelRequest, cancelLoading: loading };
}
