import Form from "features/ui/forms/form";
import Button from "features/ui/forms/button";
import FormGroup from "features/ui/forms/form-group";
import useUpdateProfile from "./setup-profile.api";

import ImageUpload from "features/ui/forms/image-upload";

import ArrowIcon from "../../assets/icons/LedgaV2 Icons/Arrow.png";

export default function ProfileImageForm() {
   const { handleFormSubmit, profileLoading } = useUpdateProfile();

   const formOptions = {
      mode: "onTouched",
      criteriaMode: "all",
      defaultValues: {
         profile_image_url: "",
      },
   };

   return (
      <Form
         id="profileForm"
         options={formOptions}
         onSubmit={(data) => handleFormSubmit(data)}
      >
         {(methods) => (
            <FormDetails
               methods={methods}
               handleSubmit={handleFormSubmit}
               profileLoading={profileLoading}
            />
         )}
      </Form>
   );
}

export const FormDetails = ({ methods, handleSubmit, profileLoading }) => {
   const {
      setValue,
      clearErrors,
      formState: { errors },
   } = methods;

   const onSuccess = (data) => {
      clearErrors("profile_image_url");
      setValue("profile_image_url", data);
   };

   // TODO: This should probably be in an .env file
   const defaultImageUrl =
      "https://ik.imagekit.io/thelastkingpin/clouddust/book%20covers/sanyay.jpg?updatedAt=1694393545671";

   return (
      <FormGroup className="mb-10 flex flex-col items-center gap-2 self-center">
         <FormGroup>
            <ImageUpload
               label="Upload Profile"
               variant="PROFILE"
               errorMessage={errors?.profile_image_url?.message}
               onSuccess={(data) => {
                  onSuccess(data);
                  handleSubmit({ profile_image_url: data });
               }}
               successLoading={profileLoading}
            />
         </FormGroup>

         <Button
            size="WIDE"
            type="submit"
            variant="PRIMARYV2"
            form="profileForm"
            color="CLEAR"
            onClick={() => {
               // default profile image
               onSuccess(defaultImageUrl);
               handleSubmit({ profile_image_url: defaultImageUrl });
            }}
         >
            Go Home
            <img src={ArrowIcon} alt="" className="h-[35px]" />
         </Button>
      </FormGroup>
   );
};
