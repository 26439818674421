import { axios } from "utils/lib/axios";

//funding
export const getSchoolAccountUrl = () =>
   "member/funding/school-account-details";

export const getFundingAccount = async (queryObj) => {
   return axios.post("member/create-funding-account", queryObj);
};

export const confirmFunding = async (queryObj) => {
   return axios.post("member/funding/confirm-payment", queryObj);
};
