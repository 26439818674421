import Button from "features/shared/forms/button";
import { IoCheckmarkCircleOutline } from "react-icons/io5";
import ModalContainer from "features/shared/modals/modal-container";
import { BiMailSend } from "react-icons/bi";

export default function EmailSuccess({
   to,
   message,
   successModal,
   toggleSuccessModal,
   modalPosition,
   outsideOnClick,
   btnText,
   btnOnClick,
}) {
   return (
      <ModalContainer
         variant="AUTO"
         modalPosition={modalPosition}
         modalDisplay={successModal}
         toggleModal={toggleSuccessModal}
         outsideOnClick={outsideOnClick}
      >
         <div className="flex flex-col items-center py-6 md:py-12">
            <BiMailSend className=" mb-4 text-6xl text-black-one" />
            <h2 className="poppins-bold mb-4 text-xl font-semibold capitalize text-black-one md:text-3xl">
               email sent
            </h2>
            <p className="poppins-regular mb-6 text-center text-base text-black-three md:text-lg">
               {message}
            </p>
            <Button onClick={btnOnClick} to={to}>
               {btnText || "home"}
            </Button>
         </div>
      </ModalContainer>
   );
}
