import { useState } from "react";

import Header from "features/shared/layout/headers/dashboard-header";
import FilterModal from "features/ui/modals/filter-modal";
import TransactionsTable from "features/transactions/table";
import useTransactions from "features/transactions/transactions.api";

export default function Transactions() {
   const filterOptions = ["all", "payout", "funding", "transfer"];
   const [queryParams, setQueryParams] = useState("");
   const [filterModalIsOpen, setFilterModalIsOpen] = useState(false);

   const {
      transactions: transactionsData,
      transactionsError,
      transactionsLoading,
      refetchTransactions,
   } = useTransactions(queryParams);

   const handleFilter = ({ option }) => {
      option === "all"
         ? setQueryParams("")
         : setQueryParams(`?transaction_type=${option}`);
   };

   return (
      <section className="wrapper min-h-screen">
         {/* <Header
            className="mb-2 pt-6 md:border-none md:text-slate-500"
            title="transactions"
            settingsUrl="../settings"
         /> */}
         <Header title="Transactions" variant={"TRANSACTIONS"} />

         <TransactionsTable
            transactionsError={transactionsError}
            refetchTransactions={refetchTransactions}
            transactionsData={transactionsData}
            transactionsLoading={transactionsLoading}
            toggleModal={() => setFilterModalIsOpen(true)}
         />
         <FilterModal
            defaultValue="all"
            options={filterOptions}
            handleSubmit={handleFilter}
            isOpen={filterModalIsOpen}
            onClose={() => setFilterModalIsOpen(false)}
         />
      </section>
   );
}
