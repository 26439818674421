import useBills from "features/bills/api/get-bills.api";
import BillCard from "features/bills/components/bill-card";
import Header from "features/shared/layout/headers/dashboard-header";
import SkeletonComponent from "features/ui/feedbacks/loading-skeleton";
import AccountsNav from "features/ui/layout/nav/accounts-nav";
import React from "react";

const Accounts = () => {
   const { getAllBillsLoading, outStandingBills } = useBills();

   return (
      <section className="wrapper flex min-h-screen w-[5/6] flex-col items-center justify-start pb-20">
         <Header title="Accounts" variant="ACCOUNTS" />
         <div className="w-full text-accent-one md:max-w-xl">
            <div className="flex w-full flex-col items-center  justify-center gap-5 self-stretch px-[20px]">
               <AccountsNav />
               {getAllBillsLoading && <SkeletonComponent />}
               {outStandingBills?.map((bill) => (
                  <BillCard key={bill._id} bill={bill} />
               ))}
            </div>
         </div>
      </section>
   );
};

export default Accounts;
