import Button from "features/ui/forms/button";
import ModalContainer from "./modal-container.component";
import { IoCheckmarkCircleOutline } from "react-icons/io5";

export default function SuccessModal({
   isOpen,
   icon,
   title,
   message,
   btnText,
   btnOnClick,
   children,
   size,
   modalPosition,
   bg,
   onClose,
   variant,
   isLoading,
   showCloseBtn,
   hideOnBlur,
}) {
   return (
      <ModalContainer
         isOpen={isOpen}
         size={size}
         role="alert"
         color={bg}
         onClose={onClose}
         variant={variant}
         showCloseBtn={showCloseBtn}
         hideOnBlur={hideOnBlur}
         modalPosition={modalPosition}
      >
         <div className="container h-screen justify-center md:h-auto md:py-20 ">
            <div className="mb-6 flex justify-center text-7xl md:text-8xl">
               {icon ? (
                  icon
               ) : (
                  <IoCheckmarkCircleOutline className="text-black-one" />
               )}
            </div>

            <h1 className="poppins-semibold text-center text-2xl font-bold capitalize text-black-one">
               {title}
            </h1>
            <p className="poppins-regular mt-1 text-center  text-black-three sm:text-base md:text-lg">
               {message}
            </p>

            {children && <div className="whitespace-pre-wrap">{children}</div>}

            <div className="mt-10">
               {btnText && (
                  <Button
                     onClick={btnOnClick}
                     size="WIDE"
                     isLoading={isLoading}
                     color={bg === "BLUE" ? "WHITE" : "BLUE"}
                  >
                     {btnText}
                  </Button>
               )}
            </div>
         </div>
      </ModalContainer>
   );
}
