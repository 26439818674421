import { BsDownload } from "react-icons/bs";
import ReceiptField from "./receipt-field";
import ArrowBack from "../layout/nav/arrow-back";
import ModalContainer from "features/shared/modals/modal-container";
import getReceipt from "./receipts";
import AccountCard from "../forms/bank-card";

export default function TransactionReceipt({
   transaction,
   modalDisplay,
   toggleModal,
   to,
}) {
   return (
      <ModalContainer
         outsideOnClick={toggleModal}
         modalDisplay={modalDisplay}
         toggleModal={toggleModal}
      >
         <ArrowBack to={to} color="GREY" />
         <div id={transaction._id}>
            <div className="mx-auto mt-20 flex max-w-[500px] flex-col items-center px-12 md:max-h-[350px] md:overflow-y-scroll">
               <h2 className="poppins-regular text-center text-[20px]">
                  Transaction Successful
               </h2>
               <p className="poppins-regular text-center text-[12px]">
                  You have successfully transferred
               </p>
               <p className="poppins-semibold mt-[20px] text-center text-[35px] text-accent-one">
                  {transaction.formattedAmount}
               </p>
               <div className="h-[50px] w-[2px] bg-black-one"></div>
               <AccountCard
                  bankName={
                     transaction.sender?.bankName || transaction.sender?.id
                  }
                  name={transaction.sender?.accountName}
                  accountNumber={transaction.sender?.accountNumber}
               />
               <p
                  onClick={() => getReceipt(transaction)}
                  className="poppins-semibold mt-[12px] text-center text-[12px]"
               >
                  download receipt
               </p>
               {/* <ReceiptField label="Date" value={transaction.formattedDate} />

               <ReceiptField label="time" value={transaction.formattedTime} />

               <ReceiptField
                  label="amount"
                  value={transaction.formattedAmount}
               />

               <ReceiptField
                  label="full amount"
                  value={transaction.originalAmount}
               />

               <ReceiptField
                  label="transaction type"
                  value={transaction.transaction_type}
               />

               <ReceiptField
                  label="reference"
                  value={transaction.transaction_refernce}
               />

               <div className="my-12">
                  <h2 className="text-sm">Sender</h2>
                  <ReceiptField label="Id" value={transaction.sender?.id} />
                  <ReceiptField
                     label="Acount Number"
                     value={transaction.sender?.accountNumber}
                  />
                  <ReceiptField
                     label="Bank Name"
                     value={transaction.sender?.bankName}
                  />
               </div>

               <div>
                  <h2 className="text-sm">Recipient</h2>
                  <ReceiptField label="Id" value={transaction.recipient?.id} />
                  <ReceiptField
                     label="Acount Number"
                     value={transaction.recipient?.accountNumber}
                  />
                  <ReceiptField
                     label="Bank Name"
                     value={transaction.recipient?.bankName}
                  />
               </div> */}
            </div>
         </div>
      </ModalContainer>
   );
}
