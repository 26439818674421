import useMember from "api/hooks/useStudent";
import { requestPayout } from "api/services/students.service";
import useAllRequests from "features/requests/api/requests.api";
import Header from "features/shared/layout/headers/dashboard-header";
import WithdrawModals from "features/withdraw/withdraw-modal";
import React, { useState } from "react";

const Withdraw = () => {
   const [withdrawModal, setWithdrawModal] = useState(true);
   const [queryParams] = useState("");

   const { memberBankDetails, ledgerBalance } = useMember();

   const { mutateRequests } = useAllRequests(queryParams);

   return (
      <section className="wrapper">
         <Header title="Withdraw" variant={"SETTINGS"} />
         <div className="text-black mx-auto h-full w-5/6 max-w-screen-sm py-10">
            <h2 className="poppins-bold mb-5 text-xl md:text-4xl">
               Enter Account
            </h2>
            <WithdrawModals
               modalDisplay={withdrawModal}
               setModalDisplay={setWithdrawModal}
               mutateFn={mutateRequests}
               withdrawService={requestPayout}
               balance={ledgerBalance}
               bankDetails={memberBankDetails}
            />
         </div>
      </section>
   );
};

export default Withdraw;
