import { useState } from "react";
import { useNavigate } from "react-router-dom";

import useRequest from "utils/hooks/useRequest";
import { useBillContext } from "../bills.context";
import { transferPayment } from "api/services/students.service";
import { useGlobalModalContext } from "features/modal/global-modal.context";

export default function usePayBill() {
   const navigate = useNavigate();
   const { state } = useBillContext();
   const [numpadModal, setNumpadModal] = useState(false);
   const { showModal, MODAL_TYPES } = useGlobalModalContext();

   const openNumpadModal = () => setNumpadModal(true);
   const closeNumpadModal = () => setNumpadModal(false);

   const { queryFn: transferFn, loading: transferLoading } =
      useRequest(transferPayment);

   const handleSubmit = ({ value: numpadPin }) => {
      const queryObj = {
         receiver_type: "bill",
         receiver_unique_id: state.id,
         transaction_pin: numpadPin,
         purpose: state.paymentPurpose,
         amount: state.makePartPayment ? state.minimumAmount : state.amount,
      };

      transferFn(queryObj, {
         onSuccess: (bill) => {
            setNumpadModal(false);
            showModal(MODAL_TYPES.RECEIPT, {
               id: bill._id,
               amount: bill.amount,
               created: bill.created_at,
               type: bill.transaction_type,
               sender: bill.sender,
               recipient: bill.receiver,
               onClose: () =>
                  navigate("../../../dashboard/accounts/paid", {
                     replace: true,
                  }),
            });
         },

         onError: (message) => {
            setNumpadModal(false);
            if (message.includes("pin you entered is incorrect")) {
               showModal(MODAL_TYPES.ERROR, {
                  title: "incorrect pin",
                  message: `the pin you entered is incorrect \nPlease check your transaction pin and try again`,
               });
            } else if (message.includes("do not have sufficient balance")) {
               showModal(MODAL_TYPES.ERROR, {
                  title: "insufficient funds",
                  message: `you do not have sufficient balance to process this request`,
                  btnText: "back",
               });
            } else {
               showModal(MODAL_TYPES.ERROR);
            }
         },
      });
   };

   return {
      state,
      numpadModal,
      transferLoading,
      handleSubmit,
      openNumpadModal,
      closeNumpadModal,
   };
}
