import { Fragment } from "react";
import { Outlet } from "react-router-dom";
import Sidebar from "features/ui/layout/nav/sidebar";

export default function DashboardNav() {
   return (
      // <Fragment>
      //    <Sidebar />
      //    <Outlet />
      // </Fragment>
      <Fragment>
         <div className="bg-[#FCFDFF] md:bg-[#EDF5FC]">
            <Sidebar />
            <Outlet />
         </div>
      </Fragment>
   );
}
