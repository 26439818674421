import { useState, useEffect } from "react";
import { MdOutlineKeyboardBackspace } from "react-icons/md";

export default function NumpadBtns({ className, pin, setPin }) {
   const [numberList, setNumberList] = useState([]);

   useEffect(() => {
      const getRandomArray = (arr) => {
         const number = Math.floor(Math.random() * 10);
         if (arr.length >= 10) {
            return arr;
         } else if (!arr.includes(number)) {
            arr.push(number);
            getRandomArray(arr);
         } else {
            getRandomArray(arr);
         }
         return arr;
      };
      setNumberList(getRandomArray([]));
   }, []);

   const handleClick = (e) => {
      if (pin.length < 4) {
         setPin((prev) => `${prev}${e}`);
      }
   };

   const handleDelete = () => {
      if (pin.length > 0) {
         setPin((prev) => `${prev.slice(0, -1)}`);
      }
   };

   const buttonStyle = `p-4 flex justify-center items-center text-2xl`;

   return (
      <div
         className={`${className} grid  min-h-[40vh] w-full max-w-[350px] grid-cols-3 md:hidden`}
      >
         {numberList.slice(0, 9).map((e, i) => (
            <button
               key={i}
               className={`${buttonStyle} inter-bold`}
               onClick={() => handleClick(e.toString())}
            >
               {e}
            </button>
         ))}
         <button className={buttonStyle}>
            <div></div>
         </button>
         <button
            className={`${buttonStyle} inter-bold`}
            onClick={() => handleClick(numberList[9].toString())}
         >
            {numberList[9]}
         </button>
         <button className={buttonStyle} onClick={handleDelete}>
            <MdOutlineKeyboardBackspace />
         </button>
      </div>
   );
}
