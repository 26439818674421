import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { useBillContext } from "../bills.context";
import { handleBillDetails } from "../bills.utils";
import Input from "features/shared/forms/input";
import Button from "features/shared/forms/button";
import ArrowBack from "features/shared/layout/nav/arrow-back";
import PageTitle from "features/shared/layout/headers/page-title";
import Spinner from "features/shared/feedbacks/spinner";
import useSingleBill from "../api/get-single-bill.api";
import ModalContainer from "features/shared/modals/modal-container";

export default function GetBillModal({ modalDisplay, toggleModal }) {
   const navigate = useNavigate();
   const [billId, setBillId] = useState("");
   const { dispatch } = useBillContext();
   const { billLoading, billError, bill, resetBill } = useSingleBill(billId);

   const handleChange = (e) => setBillId(e.target.value);

   const handleSubmit = () => {
      handleBillDetails(bill, dispatch);
      navigate("../pay-bill");
   };

   const handleBack = () => {
      resetBill("");
      setBillId("");
      toggleModal();
   };

   return (
      <ModalContainer modalDisplay={modalDisplay} toggleModal={handleBack}>
         <ArrowBack />
         <div className="container pt-16 md:py-12">
            <PageTitle
               variant="SECONDARY"
               title="Pay Bill"
               message="enter receiver details"
            />
            <img
               src="/icons/profile_icon.svg"
               alt="profile icon"
               className="filter-blue mb-10 block w-20 self-center"
            />
            <div className="mb-16 self-stretch">
               <Input
                  value={billId}
                  name="id"
                  label="bill id"
                  autoComplete="off"
                  onChange={handleChange}
                  errorMessage={{
                     requiredError: "id must be 10 digits long",
                     validityError: "please enter a valid id",
                  }}
               />

               <Input
                  variant="LINE"
                  name="id"
                  label="confirm bill name"
                  disabled
                  readOnly
                  icon={
                     billLoading && <Spinner color="GREY" size="EXTRA_SMALL" />
                  }
                  iconPosition="RIGHT"
                  isValid={!billError}
                  value={bill.title || billError}
               />
            </div>
            <Button disabled={!bill} onClick={handleSubmit}>
               continue
            </Button>
         </div>
      </ModalContainer>
   );
}
