import { useState } from "react";

import NavItem from "./nav-item";
import { signOut } from "features/auth/auth.service";

import { ReactComponent as SettingsIcon } from "../../../../assets/icons/LedgaV2 Icons/Filter.svg";
import { ReactComponent as SettingsFilledIcon } from "../../../../assets/icons/LedgaV2 Icons/Filter Complete.svg";
import { ReactComponent as SettingsBlueIcon } from "../../../../assets/icons/LedgaV2 Icons/Blue Filter.svg";

import { ReactComponent as OverviewIcon } from "../../../../assets/icons/LedgaV2 Icons/More Circle.svg";
import { ReactComponent as OverviewFilledIcon } from "../../../../assets/icons/LedgaV2 Icons/More Circle White.svg";
import { ReactComponent as OverviewBlueIcon } from "../../../../assets/icons/LedgaV2 Icons/More Circle Blue.svg";

import { ReactComponent as AccountsIcon } from "../../../../assets/icons/LedgaV2 Icons/Folder.svg";
import { ReactComponent as AccountsFilledIcon } from "../../../../assets/icons/LedgaV2 Icons/Folder White.svg";
import { ReactComponent as AccountsBlueIcon } from "../../../../assets/icons/LedgaV2 Icons/Folder Blue.svg";
import { ReactComponent as LogoutIcon } from "../../../../assets/icons/LedgaV2 Icons/Logout Final.svg";

import { ReactComponent as HistoryIcon } from "../../../../assets/icons/LedgaV2 Icons/Swap.svg";
import { ReactComponent as HistoryFilledIcon } from "../../../../assets/icons/LedgaV2 Icons/Swap White.svg";
import { ReactComponent as HistoryBlueIcon } from "../../../../assets/icons/LedgaV2 Icons/Swap Blue.svg";

export default function Sidebar() {
   const navItemClassName = "text-2xl md:text-xl mx-auto";
   const [activeLabel, setActiveLabel] = useState("");
   const handleClick = (label) => setActiveLabel(label);

   return (
      <aside className="fixed bottom-0 z-10 flex w-full justify-between bg-white px-2 transition  dark:border-none dark:bg-[white] md:top-0 md:block md:h-screen md:w-[100px] md:p-6 md:px-3 lg:w-[200px]">
         <div className="flex w-[100%] flex-row justify-between md:h-full md:flex-col md:justify-center">
            <NavItem
               label="Overview"
               to="/dashboard"
               isActive={activeLabel === "Home"}
               onClick={() => handleClick("Home")}
               icon={<OverviewIcon />}
               activeIcon={<OverviewFilledIcon />}
               smallIcon={<OverviewBlueIcon />}
            />

            <NavItem
               label="Accounts"
               isActive={activeLabel === "Accounts"}
               to="/dashboard/accounts/active"
               icon={<AccountsIcon />}
               activeIcon={<AccountsFilledIcon />}
               smallIcon={<AccountsBlueIcon />}
               onClick={() => handleClick("Accounts")}
            />

            <NavItem
               label="History"
               to="/dashboard/transactions"
               isActive={activeLabel === "Transactions"}
               icon={<HistoryIcon />}
               activeIcon={<HistoryFilledIcon />}
               smallIcon={<HistoryBlueIcon />}
               onClick={() => handleClick("Transactions")}
            />

            <NavItem
               label="Settings"
               to="/dashboard/settings"
               isActive={activeLabel === "Settings"}
               icon={<SettingsIcon />}
               activeIcon={<SettingsFilledIcon />}
               smallIcon={<SettingsBlueIcon />}
               onClick={() => handleClick("Settings")}
            />
         </div>

         {/* logout button bottom */}
         <div className="absolute bottom-0 left-0 right-0 hidden w-full font-semibold md:flex md:justify-center">
            <NavItem
               to="#"
               onClick={signOut}
               label="Logout"
               icon={<LogoutIcon className={navItemClassName} />}
            />
         </div>
      </aside>
   );
}
