import { useState } from "react";
import { useFormData } from "utils/hooks/useFormData";
import useFormValidation from "utils/hooks/useFormValidation";
import { getFormattedDate, getFormattedTime } from "utils/lib/number-formatter";
import { getIsoCurrencyFormat } from "utils/lib/number-formatter";
import { getOnchangeHandler } from "utils/lib/getOnchangeHandler";
import { useNavigate } from "react-router-dom";
import useRequest from "utils/hooks/useRequest";

export default function useTransfer(transferType, transferUrl) {
   const initialFormData = {
      id: "",
      amount: "",
      formattedAmount: "",
      paymentPurpose: "",
      recipientName: "",
   };
   const [state, dispatch] = useFormData(initialFormData);
   const { formValidation, formIsValid } = useFormValidation(state);

   const {
      queryFn: transferFn,
      loading: transferLoading,
      error: transferError,
   } = useRequest(transferUrl);

   const navigate = useNavigate();

   const [pin, setPin] = useState("");
   const [numpadModal, setNumpadModal] = useState(false);
   const [failureModal, setFailureModal] = useState(false);
   const [receiptModal, setReceiptModal] = useState(false);
   const [transactionDetails, setTransactionDetails] = useState([]);

   const toggleNumpadModal = () => setNumpadModal((prev) => !prev);

   const handleSubmit = () => {
      if (formIsValid) {
         setNumpadModal(false);
         const { amount, id, paymentPurpose } = state;
         const queryObj = {
            receiver_type: transferType,
            receiver_unique_id: id,
            amount: amount,
            transaction_pin: pin,
            purpose: paymentPurpose,
         };

         transferFn(queryObj, {
            onSuccess: (data) => {
               setTransactionDetails({
                  formattedDate: getFormattedDate(data.created_at),
                  formattedTime: getFormattedTime(data.created_at),
                  formattedAmount: getIsoCurrencyFormat(data.amount),
                  amount: data.amount,
                  transaction_type: data.transaction_type,
                  transaction_reference: data._id,
                  sender: { id: data.from },
                  recipient: { id: data.to },
               });
               setReceiptModal(true);
            },
            onError: () => setFailureModal(true),
         });
         setPin("");
         dispatch({ type: "CLEAN_SLATE", payload: initialFormData });
      }
   };

   const handleChange = getOnchangeHandler(dispatch);

   const handleNext = () => setNumpadModal(true);

   const handleRetry = () => {
      setFailureModal(false);
      navigate("../../../dashboard");
   };

   return {
      pin,
      setPin,
      state,
      dispatch,
      formValidation,
      transferLoading,
      transferError,
      numpadModal,
      failureModal,
      receiptModal,
      setReceiptModal,
      transactionDetails,
      toggleNumpadModal,
      handleSubmit,
      handleChange,
      handleNext,
      handleRetry,
   };
}
