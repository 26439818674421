import WelcomeSlideParentWrapper from "./slide-parent-wrapper";

export default function WelcomeSlideTwo({ setScreen }) {
   return (
      <WelcomeSlideParentWrapper
         url="/icons/communication, transportation _ fly, airplane, paper, memo, man, people, transport, delivery 1Student.svg"
         title="Send With Ease"
         text="Send money to your friends and peers with ease without a bank account and no hidden charges"
         screen={2}
         setScreen={setScreen}
      />
   );
}
