import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useRequest from "utils/hooks/useRequest";

import { useMember } from "features/auth/auth-context";
import { getFundingAccount } from "../funding.service";
import { useGlobalModalContext } from "features/modal/global-modal.context";

export default function useCreateFundingAccount() {
   const navigate = useNavigate();
   const { member, mutateMember, getMemberLoading } = useMember();
   const { showModal, MODAL_TYPES } = useGlobalModalContext();
   const [createRefLoading, setCreateRefLoading] = useState(false);
   const { queryFn: createAccountFn } = useRequest(getFundingAccount);

   useEffect(() => {
      if (member) {
         if (member?.meta?.funding?.pending_transaction_reference) {
            navigate("/accounts/funding/actions");
         }
         setCreateRefLoading(false);
      }
   }, [member]);

   const handleCreate = ({ amount }) => {
      const queryObj = { amount };
      setCreateRefLoading(true);
      createAccountFn(queryObj, {
         onSuccess: async () => {
            await mutateMember();
            navigate("/accounts/funding/actions");
         },
         onError: (err) => {
            setCreateRefLoading(false);
            showModal(MODAL_TYPES.ERROR, {
               message:
                  "funding account cannot be created successfully, please check your network and try again.",
            });
         },
      });
   };

   return {
      handleCreate,
      createRefLoading,
      getMemberLoading,
   };
}
