import { useState } from "react";
import { useNavigate } from "react-router-dom";
import useRequest from "utils/hooks/useRequest";
import { useFormData } from "utils/hooks/useFormData";
import { resetPasswordEmail } from "api/services/settings.service";
import { resetPasswordNewPassword } from "api/services/settings.service";
import { resetPasswordOTP } from "api/services/settings.service";
import useFormValidation from "utils/hooks/useFormValidation";

export default function useResetPassword(auth, modalDisplay, toggleModal) {
   const initialFormData = {
      email: "",
      password: "",
      passwordConfirmation: "",
   };
   //form states
   const [state, dispatch] = useFormData(initialFormData);
   const { formValidation, formIsValid } = useFormValidation(state);

   //modals states
   const [otpSuccessModal, setOtpSuccessModal] = useState(false);
   const [resetFormModal, setResetFormModal] = useState(false);
   const [verificationModal, setVerificationModal] = useState(false);
   const [resetSuccessModal, setResetSuccessModal] = useState(false);
   const [resetPasswordModal, setResetPasswordModal] = useState(true);

   //other prop states
   const navigate = useNavigate();
   const [verificationCode, setVerificationCode] = useState("");
   const [otpVerificationErrorMessage, setOTPVerificationErrorMessage] =
      useState("");

   const [errorModal, setErrorModal] = useState("");

   //get query functions for making post requests
   const { queryFn: verifyEmailFn, loading: emailSubmitLoading } =
      useRequest(resetPasswordEmail);
   const { queryFn: verifyOTPFn, loading: otpVerificationLoading } =
      useRequest(resetPasswordOTP);
   const { queryFn: newPasswordFn, loading: passwordResetLoading } = useRequest(
      resetPasswordNewPassword,
   );

   // the container display and toggle is toggled on by default and
   // changed only within the component itself when it is used in settings/reset-password
   // when it is used in auth pages (signup/signin) it is toggled on and off
   // by props from the auth pages
   const modalContainer = auth ? modalDisplay : resetPasswordModal;
   const toggleModalContainer = auth
      ? toggleModal
      : () => setResetPasswordModal((prev) => !prev);

   //1. submit email to backend for verification code to be sent
   const handleEmailSubmit = () => {
      if (formValidation.email) {
         const queryObj = { email: state.email };
         verifyEmailFn(queryObj, {
            onSuccess: () => {
               toggleModalContainer();
               setOtpSuccessModal(true);
            },
            onError: () => setErrorModal(true),
         });
      }
   };

   //2. submit otp to check match
   const handleVerification = () => {
      const { email } = state;
      const querObj = { email, otp: verificationCode };
      verifyOTPFn(querObj, {
         onSuccess: () => setResetFormModal(true),
         onError: (message) => {
            message.includes("OTP is not correct") &&
               setOTPVerificationErrorMessage(message);
         },
      });
   };

   //3. submit new password form
   const handleSubmit = () => {
      if (formIsValid) {
         const { email, password } = state;
         const queryObj = {
            email,
            otp: verificationCode,
            new_password: password,
         };
         newPasswordFn(queryObj, {
            onSuccess: () => {
               setResetFormModal(false);
               setResetSuccessModal(true);
            },
            onError: () => setErrorModal(true),
         });
      }
   };

   return {
      state,
      dispatch,
      formValidation,
      otpSuccessModal,
      resetFormModal,
      verificationModal,
      resetSuccessModal,
      resetPasswordModal,
      setVerificationCode,
      otpVerificationErrorMessage,
      emailSubmitLoading,
      otpVerificationLoading,
      passwordResetLoading,
      verificationCode,
      errorModal,
      modalContainer,
      toggleModalContainer,
      navigate,
      handleEmailSubmit,
      handleVerification,
      handleSubmit,
      setOtpSuccessModal,
      setResetFormModal,
      setVerificationModal,
      setResetSuccessModal,
      setOTPVerificationErrorMessage,
   };
}
