import WelcomeSlideParentWrapper from "./slide-parent-wrapper";

export default function WelcomeSlideOne({ setScreen }) {
   return (
      <WelcomeSlideParentWrapper
         url="/icons/finance _ savings, saving, bank, piggy bank, coin, coins, interest, man, people 1Student.svg"
         title="Save Money"
         text="Store your school pocket money safely with your Ledga without worrying about deductions or bank charges"
         screen={1}
         setScreen={setScreen}
      />
   );
}
