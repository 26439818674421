import { SWRConfig } from "swr";
import { BrowserRouter } from "react-router-dom";

import { ApiRoutes } from "api/routes/router";
import { fetcher } from "utils/lib/fetcher";
import { GlobalModalProvider } from "features/modal/global-modal.context";

function App() {
   const options = {
      revalidateOnReconnect: true,
      revalidateOnFocus: false,
      errorRetryCount: 2,
   };

   return (
      <SWRConfig value={{ fetcher: fetcher, ...options }}>
         <BrowserRouter>
            <GlobalModalProvider>
               <ApiRoutes />
            </GlobalModalProvider>
         </BrowserRouter>
      </SWRConfig>
   );
}

export default App;
