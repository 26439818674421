import useSWR from "swr";
import { useState } from "react";

export default function useSingleBill(id) {
   const IdIsValid = id.length === 6;
   const [bill, setBill] = useState("");
   const [billError, setBillError] = useState("");

   const resetBill = () => {
      setBill("");
      setBillError("");
   };

   const { data, error, isValidating } = useSWR(
      IdIsValid ? `member/payments/bill/verify/${id}` : null,
      {
         onSuccess: (data) => {
            setBill(data);
         },
         onError: (err) => {
            err?.message.includes("No bill found")
               ? setBillError(err?.message)
               : setBillError("something went wrong. Please try again");
         },
      },
   );
   const billLoading = (IdIsValid && !data && !error) || isValidating;
   return { bill, billLoading, billError, resetBill };
}
