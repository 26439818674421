import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { setToLS } from "utils/lib/storage";
import useRequest from "utils/hooks/useRequest";
import { verifyEmailOTP } from "features/auth/auth.service";

export default function useVerifyEmail() {
   const navigate = useNavigate();
   const { userId } = useParams();

   const [otp, setOTP] = useState("");
   const [error, setError] = useState("");

   const handleChange = (value) => {
      setOTP(value);
      error && setError("");
   };

   const { queryFn: verifyEmailFn, loading } = useRequest(verifyEmailOTP);

   const handleVerification = () => {
      const queryObj = { id: userId, otp };
      verifyEmailFn(queryObj, {
         onSuccess: (data) => {
            setToLS(data);
            navigate("../welcome");
         },

         onError: (message) => {
            message.includes("OTP is not correct")
               ? setError(message)
               : setError("Something went wrong. Please try again");
         },
      });
   };

   return {
      handleChange,
      handleVerification,
      verificationLoading: loading,
      verificationError: error,
   };
}
