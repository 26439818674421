import Button from "features/ui/forms/button";
import { IoCheckmarkCircleOutline } from "react-icons/io5";

export default function EmailSuccess({ btnOnClick }) {
   return (
      <div className="flex flex-col items-center py-16 md:py-12">
         <IoCheckmarkCircleOutline className="mb-4 text-6xl" />
         <h2 className="mb-4 text-3xl font-semibold capitalize">email sent</h2>
         <p className="mb-6 text-lg text-accent-one">
            Check your mailbox for a reset email
         </p>
         <Button onClick={btnOnClick}>continue</Button>
      </div>
   );
}
