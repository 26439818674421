import useCreateFundingAccount from "features/fund-account/api/create-funding-account.api";
import CreateFundingAcct from "features/fund-account/components/create-funding-account";
import LoadingModal from "features/ui/feedbacks/loading-modal";

export default function FundAccountCreate() {
   const { handleCreate, createRefLoading, getMemberLoading } =
      useCreateFundingAccount();

   if (getMemberLoading) {
      return <LoadingModal isOpen={true} modalPosition="CENTER" />;
   }

   return (
      <section className="wrapper">
         <div className="text-black mx-auto  h-full w-5/6 max-w-screen-sm px-10 py-10">
            <CreateFundingAcct
               handleCreate={handleCreate}
               createRefLoading={createRefLoading}
            />
         </div>
      </section>
   );
}
