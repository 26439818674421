import ReceiptField from "./receipt-field";

export default function UserDetailsFields({ name, data, className }) {
   return (
      <div className={className}>
         {name && data && (
            <h2 className="poppins-regular text-sm text-[#707070]">{name}</h2>
         )}
         <div className="text-right">
            <p className="poppins-semibold justify-start text-sm text-[13px] capitalize text-[#333333]">
               {data?.uniqueId || data?.id}{" "}
            </p>
            <p className="poppins-semibold justify-start text-sm text-[13px] capitalize text-[#333333]">
               {data?.name}{" "}
            </p>
            <p className="poppins-semibold justify-start text-sm text-[13px] capitalize text-[#333333]">
               {data?.inClass || data?.forClass}{" "}
            </p>
            <p className="poppins-semibold justify-start text-sm text-[13px] capitalize text-[#333333]">
               {data?.accountNumber}{" "}
            </p>
            <p className="poppins-semibold justify-start text-sm text-[13px] capitalize text-[#333333]">
               {data?.bankName}{" "}
            </p>
         </div>
      </div>
   );
}
