import { Link } from "react-router-dom";
export default function NotFound() {
   return (
      <section>
         <h1></h1>
         <h2>Sorry you've hit a resource that does not exist</h2>
         <p>please return to the</p> <Link to="#">dashboard</Link>
      </section>
   );
}
