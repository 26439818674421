import * as yup from "yup";
import NumberFormat from "react-number-format";

import Form from "features/ui/forms/form";
import Button from "features/ui/forms/button";
import Input from "features/ui/forms/input";
import CardContainer from "features/ui/cards/card-container";
import AccountCard from "features/shared/forms/bank-card";
import useMember from "api/hooks/useStudent";

export default function CreateFundingAcct({ handleCreate, createRefLoading }) {
   const formOptions = {
      mode: "onTouched",
      criteriaMode: "all",
      defaultValues: { amount: "" },
   };

   const schema = yup.object().shape({
      amount: yup
         .number("please enter a valid amount")
         .positive("please enter a valid amount")
         .required("amount cannot be empty")
         .typeError("please enter a valid amount"),
   });

   const { memberId, member } = useMember();

   return (
      <CardContainer area="THIN" position="CENTER">
         <div className="full-page container my-12 justify-center px-10">
            <p className="poppins-regular poppins-semibold  mb-12 text-center text-lg capitalize text-black-one sm:text-base md:text-lg">
               Enter Funding Amount
            </p>
            <AccountCard bankName={memberId} name={member?.name} />

            <Form
               id="createFundingAcct"
               options={formOptions}
               className="mb-28 self-stretch"
               onSubmit={handleCreate}
               schema={schema}
            >
               {({
                  control,
                  Controller,
                  formState: { errors, dirtyFields },
               }) => {
                  return (
                     <Controller
                        control={control}
                        name="amount"
                        render={({ field: { onChange, name, value } }) => (
                           <NumberFormat
                              prefix={"₦ "}
                              thousandSeparator={true}
                              label="amount"
                              placeholder="₦"
                              variant="BASE"
                              isEmpty={!dirtyFields.amount}
                              error={errors.amount}
                              customInput={Input}
                              name={name}
                              value={value}
                              onValueChange={(v) => {
                                 onChange(Number(v.value));
                              }}
                           />
                        )}
                     />
                  );
               }}
            </Form>

            <Button
               disabled={createRefLoading}
               size="WIDE"
               type="submit"
               form="createFundingAcct"
               isLoading={createRefLoading}
            >
               Generate
            </Button>
         </div>
      </CardContainer>
   );
}
