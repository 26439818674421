import { getFormattedAmount } from "utils/lib/number-formatter";

export const handleBillDetails = async (data, dispatch) => {
   await dispatch({
      type: "UPDATE_INPUT",
      payload: {
         id: data.unique_id,
         billName: data.title,
         amount: data.amount,
         minimumAmount: data.minimum_amount || data.minimumAmount,
         formattedAmount: getFormattedAmount(data.amount),
         formattedMinimumAmount: getFormattedAmount(
            data.minimum_amount || data.minimumAmount,
         ),
         paymentPurpose: data.title,
         partPaymentIsAllowed: data.allow_part_payment || data.allowPartPayment,
      },
   });
};
