import RequestInfo from "./request-info";
import Button from "features/ui/forms/button";
import Spinner from "features/ui/feedbacks/spinner";
import useRequestActions from "../api/request-actions";
import ArrowBack from "features/ui/layout/nav/arrow-back";
import PageTitle from "features/ui/layout/headers/page-title";
import { getFormattedDate } from "utils/lib/number-formatter";
import { getFormattedAmount } from "utils/lib/number-formatter";
import ModalContainer from "features/modal/modal-container.component";

export default function RequestActions({ isOpen, onClose, selectedRequest }) {
   const { cancelRequest, cancelLoading } = useRequestActions(
      selectedRequest._id,
      onClose,
   );

   const formattedAmount = getFormattedAmount(selectedRequest.amount);
   const formattedDate =
      selectedRequest.created && getFormattedDate(selectedRequest.created);
   const recipient = selectedRequest?.paid_to;

   return (
      <ModalContainer isOpen={isOpen} onClose={onClose}>
         <div className="container min-h-screen justify-center md:min-h-0 md:py-12">
            <ArrowBack handleBack={onClose} />
            <PageTitle
               variant="SECONDARY"
               title="cancel request"
               message="Permanently Cancel This request? Please note that this action cannot be reversed"
            />
            <div className="mb-12 self-start">
               <RequestInfo title="name" value={recipient?.accountName} />
               <RequestInfo title="amount" value={formattedAmount} />
               <RequestInfo
                  title="account no"
                  value={recipient?.accountNumber}
               />
               <RequestInfo title="bank name" value={recipient?.bankName} />
               <RequestInfo title="date" value={formattedDate} />
               <RequestInfo title="status" value={selectedRequest.status} />
            </div>
            <Button color="RED" onClick={cancelRequest} size="WIDE">
               {cancelLoading ? <Spinner color="WHITE" /> : "cancel request"}
            </Button>
         </div>
      </ModalContainer>
   );
}
