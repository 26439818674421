import { Fragment } from "react";
import UserDetails from "./user-details";
import NumPadModal from "features/shared/numpad/numpad-modal";
import TransactionDetails from "./transaction-details";
import FailModal from "features/shared/feedbacks/failure-modal";
import LoadingModal from "features/shared/feedbacks/loading-modal";
import TransactionReceipt from "features/shared/receipt/transaction-receipt";
import useTransfer from "./transfer.api";
import { transferPayment } from "api/services/students.service";

export default function Transfer({
   step,
   optionsObj,
   pageName,
   selectLabel,
   transferType,
}) {
   const {
      pin,
      setPin,
      state,
      dispatch,
      formValidation,
      transferLoading,
      transferError,
      numpadModal,
      failureModal,
      receiptModal,
      setReceiptModal,
      transactionDetails,
      toggleNumpadModal,
      handleSubmit,
      handleChange,
      handleNext,
      handleRetry,
   } = useTransfer(transferType, transferPayment);

   switch (step) {
      case 2: {
         return (
            <Fragment>
               <TransactionDetails
                  state={state}
                  dispatch={dispatch}
                  optionsObj={optionsObj}
                  selectLabel={selectLabel}
                  handleChange={handleChange}
                  handleNext={handleNext}
                  formValidation={formValidation}
                  pageName={pageName}
               />

               <NumPadModal
                  pin={pin}
                  setPin={setPin}
                  amount={state.formattedAmount}
                  receiver={state}
                  title="Enter Pin"
                  // message={
                  //    transferType === "student"
                  //       ? "Transfer to peer"
                  //       : `pay ${transferType}`
                  // }
                  btnText="proceed"
                  btnOnClick={handleSubmit}
                  modalDisplay={numpadModal}
                  toggleModal={toggleNumpadModal}
               />

               <LoadingModal
                  modalDisplay={transferLoading}
                  message="please wait while your transaction is processed"
               />

               <TransactionReceipt
                  modalDisplay={receiptModal}
                  to={"../"}
                  // modalDisplay={true}
                  transaction={transactionDetails}
                  toggleModal={() => setReceiptModal((prev) => !prev)}
               />

               <FailModal
                  title="Failed Transaction"
                  message={transferError}
                  modalDisplay={failureModal}
                  btnText="try again"
                  btnOnClick={handleRetry}
               />
            </Fragment>
         );
      }

      default: {
         return (
            <UserDetails
               state={state}
               dispatch={dispatch}
               handleChange={handleChange}
               transferType={transferType}
               pageName={pageName}
               formValidation={formValidation}
            />
         );
      }
   }
}
