import { useEffect, useState } from "react";
import { createContext, useContext } from "react";
import { Outlet } from "react-router-dom";
import { Navigate, useNavigate } from "react-router-dom";
import useSWRImmutable from "swr/immutable";

import { getMemberUrl } from "features/auth/auth.service";
import { getFromLS } from "utils/lib/storage";
import { signOut } from "features/auth/auth.service";
import { getFormattedAmount } from "utils/lib/number-formatter";
import { decodeJWT } from "./auth.utils";

const authContext = createContext({
   member: "",
   memberId: "",
   memberBankDetails: "",
   ledgerBalance: "",
   getMemberError: "",
   getMemberLoading: "",
   mutateMember: "",
});

//authContext is created and consumed once here in useStudent
export const useMember = () => useContext(authContext);

export const AuthContextProvider = () => {
   const [tokenIsValid, setTokenIsValid] = useState(false);
   const [memberId, setMemberId] = useState("");
   const [ledgerBalance, setLedgerBalance] = useState("");
   const [memberBankDetails, setMemberBankDetails] = useState({});
   // const [inClass, setInClass] = useState([]);
   const navigate = useNavigate();

   useEffect(() => {
      const token = getFromLS("token");
      if (!token) {
         <Navigate to="/signin" replace />;
      } else {
         const decoded = decodeJWT(token);
         if (decoded.exp * 1000 < Date.now()) {
            signOut();
         } else {
            setTokenIsValid(true);
         }
      }
      // eslint-disable-next-line.
   }, []);

   const {
      data: member,
      error: memberError,
      mutate: mutateMember,
   } = useSWRImmutable(tokenIsValid ? getMemberUrl : null);

   const getMemberError = memberError;
   const getMemberLoading = !member && !memberError;

   useEffect(() => {
      if (member) {
         if (!member.meta.has_accepted_invite) {
            navigate("../setup", { replace: true });
         } else if (!member.meta?.hasSetTransactionPin) {
            navigate("../create-pin", { replace: true });
         } else {
            const {
               bank_name: bankName,
               account_name: accountName,
               account_number: accountNumber,
            } = member.bank_details;
            setMemberId(member.unique_id);
            setLedgerBalance(getFormattedAmount(member.meta.balance));
            setMemberBankDetails({ bankName, accountName, accountNumber });
         }
      }
   }, [member, navigate]);

   return (
      <authContext.Provider
         value={{
            member,
            memberId,
            memberBankDetails,
            ledgerBalance,
            getMemberError,
            getMemberLoading,
            mutateMember,
         }}
      >
         <Outlet />
      </authContext.Provider>
   );
};
