import { useEffect, useRef } from "react";
import { useWindowResize } from "utils/hooks/useWindowResize";

export default function NumPadInput({
   className,
   pin,
   handleSubmit,
   setPin,
   bg,
}) {
   const inputRef = useRef();
   const { width } = useWindowResize();

   useEffect(() => {
      if (inputRef) {
         inputRef?.current?.focus();
      }
   });

   const handlePinChange = (e) => {
      const { value } = e.target;
      if (pin.length <= 4) {
         setPin(value);
      }
   };

   //autofocus on input when blurred
   const handleBlur = () => {
      setTimeout(() => {
         inputRef?.current?.focus();
      }, 100);
   };

   const handleKeyDown = (e) => {
      if (e.keyCode === 13) {
         handleSubmit();
      }
   };

   //styles
   const getColor = (position) => {
      if (pin.length === position) {
         return "md:outline-primary md:outline md:outline-2 md:outline-offset-2";
      }
      return pin.length > position ? "bg-white" : "bg-gray-200";
   };

   const fieldStyle = `
      w-5 h-5
      border-2 
      rounded-2xl
      md:text-3xl
      md:border-primary
      md:w-16 md:h-16
      md:rounded-xl 
      md:flex md:justify-center md:items-center
   `;

   return (
      <div className={`${className} relative mb-10 md:mb-[100px]`}>
         <input
            autoComplete="off"
            ref={inputRef}
            value={pin}
            name="pin"
            onChange={handlePinChange}
            onKeyDown={handleKeyDown}
            type="number"
            readOnly={width > 640 ? false : true}
            className="bg-black-four opacity-0"
            onBlur={handleBlur}
         />
         <div className="md: absolute top-0 left-2/4 mt-4 flex max-w-[250px] -translate-x-2/4 justify-between gap-4 text-accent-one md:max-w-none">
            <div className={`${fieldStyle} ${getColor(0)}`}>
               <span className="hidden md:block">{pin[0]}</span>
            </div>
            <div className={`${fieldStyle} ${getColor(1)}`}>
               <span className="hidden md:block">{pin[1]}</span>
            </div>
            <div className={`${fieldStyle} ${getColor(2)}`}>
               <span className="hidden md:block">{pin[2]}</span>
            </div>
            <div className={`${fieldStyle} ${getColor(3)}`}>
               <span className="hidden md:block">{pin[3]}</span>
            </div>
         </div>
      </div>
   );
}
