import CardContainer from "features/ui/cards/card-container";
import ProfileImageForm from "features/setup/profile-form";

export default function SetUpProfile() {
   return (
      <CardContainer area="MEDIUMV2" position="CENTER" variant="NUMPAD">
         <div className="flex h-full min-h-[500px] w-full flex-col items-center justify-center">
            <ProfileImageForm />
         </div>
      </CardContainer>
   );
}
