import WelcomeSlideParentWrapper from "./slide-parent-wrapper";

export default function WelcomeSlideThree({ setScreen }) {
   return (
      <WelcomeSlideParentWrapper
         url="/icons/shopping _ confirm, approve, complete, shop, cart, purchase, invoice, receipt, woman 1Student.svg"
         title="Pay Merchants"
         text="Make payments to merchants and organisation vendors without worrying about transfer charges and failed transactions"
         screen={3}
         setScreen={setScreen}
      />
   );
}
