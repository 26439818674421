import useSetup from "features/setup/setup.api";
import PersonalDetailsForm from "features/setup/personal-details-form";
import BankDetailsForm from "features/bank-details/bank-details-form";
import LoadingModal from "features/shared/feedbacks/loading-modal";
import SetupLayout from "features/setup/setup-layout.component";

export default function SetUp() {
   const {
      screen,
      setupLoading,
      classIsRequired,
      handleBack,
      handleNextScreen,
      handleFormSubmit,
   } = useSetup();

   switch (screen) {
      case 2: {
         return (
            <SetupLayout
               title="bank details"
               message="setup your account for receiving payouts and more stuffs"
               handleBack={handleBack}
               first={false}
               screen={screen}
            >
               <BankDetailsForm
                  btnText="finish"
                  onSubmit={handleFormSubmit}
                  modalPosition="CENTER"
               />
               <LoadingModal
                  modalPosition="CENTER"
                  isOpen={setupLoading}
                  message="Wait a moment while we create your unique School ID"
               />
            </SetupLayout>
         );
      }
      default: {
         return (
            <SetupLayout
               title="personal details"
               message="provide basic personal information to get started"
               handleBack={handleBack}
               first={true}
               screen={screen}
            >
               <PersonalDetailsForm
                  handleNext={handleNextScreen}
                  classIsRequired={classIsRequired}
               />
            </SetupLayout>
         );
      }
   }
}
