import { useRef } from "react";
import { useEffect } from "react";
import { classNames } from "utils/lib/getClassName";

const VARIANT_MAPS = {
   FULL_SCREEN: "min-h-full w-full md:min-h-0 md:rounded-xl",
   NUMPAD:
      "min-h-full w-full md:min-h-0 md:rounded-xl flex flex-col items-center justify-center",
   AUTO: "w-5/6",
};
const AREA_MAPS = {
   THIN: "md:max-w-[430px]",
   MEDIUM: "md:max-w-[550px] lg:max-w-screen-sm",
   LARGE: "md:max-w-[550px] lg:max-w-screen-md",
   CUSTOM:
      "md:max-w-[70%] lg:max-w-[70%] md:top-[530px] lg:top-[350px] md:h-[80%] relative",
};
const COLOR_MAPS = (variant) => {
   return {
      BLUE: `bg-primary text-white ${
         variant === "NUMPAD" && "md:bg-white md:text-secondary"
      }`,
      WHITE: `bg-mainGrey-one text-base-one ${
         variant === "NUMPAD" && "md:bg-white md:text-secondary"
      }`,
      GRAY: `bg-[#F3F3F3] text-accent-one ${
         variant === "NUMPAD" && "md:bg-[#F3F3F3] md:text-secondary h-screen"
      }`,
   };
};
const POSITION_MAPS = {
   CENTER: " ",
   RIGHT: "md:-translate-x-[calc(50%-var(--sidebar-width))]",
};

export default function ModalContainer({
   children,
   modalDisplay,
   toggleModal,
   outsideOnClick,
   variant = "FULL_SCREEN",
   area = "MEDIUM",
   color = "WHITE",
   modalPosition = "RIGHT",
}) {
   const modalRef = useRef(null);

   useEffect(() => {
      const handleClick = (e) => {
         if (modalRef.current && !modalRef.current.contains(e.target)) {
            outsideOnClick ? outsideOnClick() : toggleModal && toggleModal();
         }
      };
      document.addEventListener("click", handleClick, true);
      return () => {
         document.removeEventListener("click", handleClick, true);
      };
   }, [outsideOnClick, toggleModal]);

   //styles
   const modalWrapper = classNames(
      "fixed z-20 top-0 left-0 w-full h-full bg-black bg-opacity-30",
      modalDisplay ? "block" : "hidden",
   );

   const modalWrapper2 = classNames(
      "z-20 h-screen bg-[#F3F3F3] wrapper-alt min-h-screen bg-white",
      modalDisplay ? "block" : "hidden",
   );

   const modalClass = classNames(
      "absolute top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 border border-gray overflow-hidden",
      VARIANT_MAPS[variant],
      AREA_MAPS[area],
      COLOR_MAPS(variant)[color],
      POSITION_MAPS[modalPosition],
   );

   const modalClass2 = classNames(
      "border border-gray h-full  overflow-hidden",
      // VARIANT_MAPS[variant],
      // AREA_MAPS[area],
      COLOR_MAPS(variant)[color],
      // POSITION_MAPS[modalPosition],
   );

   if (!modalDisplay) {
      return null;
   }

   return (
      <div className={area === "CUSTOM" ? modalWrapper2 : modalWrapper}>
         <div
            tabIndex={1}
            ref={modalRef}
            className={area === "CUSTOM" ? modalClass2 : modalClass}
         >
            {children}
         </div>
      </div>
   );
}

/* 
   null is returned when display is hidden because of the condition
   <modalRef.current && !modalRef.current.contains(e.target) in the useEffect above>. 
   without returning null this condition will be true once the component mounts and the modal 
   will be toggled on when any part of the document is clicked.  That is modalRef.current will be 
   false as the reference won't be attached until the modal actually mounts in the DOM
*/
