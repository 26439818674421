import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { completeSetup } from "./setup.service";
import useRequest from "utils/hooks/useRequest";
import { useMember } from "features/auth/auth-context";
import { useGlobalModalContext } from "features/modal/global-modal.context";

export default function useSetup() {
   /*
      the personal details state used here above to temporarily hold 
      personalDetails data once that part of the form is filled
      as the submission of the form doesn't take place there
      but in bank details form
   */
   const [personalDetails, setPersonalDetails] = useState({
      firstname: "",
      lastname: "",
      class: "",
      address: "",
      date_of_birth: "",
   });

   const modalPosition = "CENTER";

   const [screen, setScreen] = useState(1);

   const navigate = useNavigate();
   const { mutateMember } = useMember();
   const { showModal, hideModal, MODAL_TYPES } = useGlobalModalContext();
   const { queryFn: setupFn, loading: setupLoading } =
      useRequest(completeSetup);

   // const classIsRequired = student && !student.class.currentClass;

   /*
    *handle functions
    */
   const handleBack = () => {
      setScreen((prev) => prev - 1);
   };

   const handleNextScreen = (data) => {
      setScreen((prev) => prev + 1);
      setPersonalDetails((prev) => ({ ...prev, ...data }));
   };

   const handleFinish = async () => {
      showModal(MODAL_TYPES.LOADING, { modalPosition: modalPosition });
      await mutateMember();
      navigate("../create-pin");
      hideModal();
   };

   const handleFormSubmit = (bankDetails) => {
      const queryObj = {
         ...personalDetails,
         ...bankDetails,
      };

      showModal(MODAL_TYPES.LOADING, { modalPosition: modalPosition });

      setupFn(queryObj, {
         onSuccess: () => {
            showModal(MODAL_TYPES.SUCCESS, {
               size: "THIN",
               modalPosition: modalPosition,
               title: "setup complete",
               message: `You have successfully created your ledga`,
               btnText: "continue",
               showCloseBtn: false,
               onClose: handleFinish,
               btnOnClick: handleFinish,
            });
         },

         onError: (message) => {
            if (message.includes("already setup")) {
               showModal(MODAL_TYPES.ERROR, {
                  modalPosition: modalPosition,
                  title: "Setup Already Completed",
                  to: "../signin",
                  message: `${message} Please signin to continue`,
                  btnText: "signin",
                  btnOnClick: hideModal,
               });
            } else {
               showModal(MODAL_TYPES.ERROR, {
                  modalPosition: modalPosition,
                  btnOnClick: () => window.location.reload(),
               });
            }
         },
      });
   };

   return {
      screen,
      setupLoading,
      // classIsRequired,
      handleBack,
      handleNextScreen,
      handleFormSubmit,
   };
}
