import Button from "features/ui/forms/button";
import useFundingActions from "../api/funding-actions.api";
import AccountCreated from "./account-created";
import AccountExpired from "./account-expired";
import CardContainer from "features/ui/cards/card-container";

export default function FundingActions({ funding }) {
   const {
      handleAction, //
      timer,
      expired,
      amount,
      accountNo,
   } = useFundingActions(funding);

   return (
      <CardContainer showCloseBtn={false} hideOnBlur={false} position="CENTER">
         <div className="container min-h-screen justify-center md:my-12 md:min-h-0">
            <div className="mb-6">
               {expired ? (
                  <AccountExpired />
               ) : (
                  <AccountCreated
                     timer={timer}
                     amount={amount}
                     accountNo={accountNo}
                  />
               )}
            </div>
            <div className="mx-auto flex justify-between gap-2">
               <Button onClick={() => handleAction("confirm")}>
                  <span className="text-xs sm:text-sm">confirm</span>
               </Button>

               <Button onClick={() => handleAction("cancel")} color="RED">
                  <span className="text-xs sm:text-sm">cancel</span>
               </Button>
            </div>
         </div>
      </CardContainer>
   );
}
