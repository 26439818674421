import React from "react";
import { BsPersonFill } from "react-icons/bs";

import { classNames } from "utils/lib/getClassName";

const COLORMAPS = {
   FUNDING:
      "mb-[20px] flex w-full bg-accent-one items-center  gap-6 rounded-lg p-4 shadow-sm shadow-faintShadow-one",
   NORMAL:
      "mb-[20px] flex w-full bg-white items-center  gap-6 rounded-lg px-[10px] py-[10px] shadow-sm shadow-faintShadow-one",
   TRANSFER:
      "mb-[20px] flex w-full bg-accent-four items-center  gap-6 rounded-lg p-4 shadow-sm shadow-faintShadow-one",
};

const TEXTMAPS = {
   FUNDING: "text-white",
   NORMAL: "text-black-one",
   TRANSFER: "text-accent-one",
};
const SUBMAPS = {
   FUNDING: "text-white",
   NORMAL: "text-black-two",
   TRANSFER: "text-accent-one",
};

const UserCard = ({ code, name, image, variant = "NORMAL", amount }) => {
   const bgClass = classNames(COLORMAPS[variant]);
   const textClass = classNames(TEXTMAPS[variant], "poppins-bold");
   const textSubClass = classNames(SUBMAPS[variant], "poppins-regular");

   return (
      <div className={bgClass}>
         <img
            src={image}
            alt="bank account"
            className="h-[60px] w-[60px] rounded-[10px] md:h-[50px]"
         />
         {/* <BsPersonFill size={40} /> */}
         <div className="flex flex-col gap-1 text-xl md:text-2xl">
            <p className={textClass}>{code}</p>
            <p className={textSubClass}>{name}</p>
            <p className={textSubClass}>{amount}</p>
         </div>
      </div>
   );
};

export default UserCard;
